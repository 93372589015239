import {states} from './states.js';
import {closedCaptionsIcon} from './closedCaptionsIcon.js';
import {chart} from './chart.js';
import {audioContainer} from './audioContainer.js';

// Closed captions
var closedCaptions = (function(){

	//state
	var captionsSupported = false;

	//data
	var staticCaptionMessage = "(Static)";
	var audioElements = [];
	var captions = [];
	var captionContainer = [];

	//cache DOM
	var $el = $('#closed-captions');
	var $errorMsg = $el.find('#errorMessage');
	var $captionStatic = $el.find('#captionStatic');
	var captionContainerStatic = $captionStatic.find('span')[0];
	var $allCaptions = $el.find('.caption');

	function init(){
		if (!document.createElement('track').track)  {
			console.log('Track element is not supported in your browser.');
			$errorMsg.fadeIn( 500 );
		} else {
			captionsSupported = true;
			console.log("captionsSupported", captionsSupported);
			// Get audio elements array
			audioElements = audioContainer.getAudioElements();
			// Build HTML elements and arrays
			for (let i=0; i < audioElements.length-2; i++) {
				// Create caption element
				initCaptionElement(i);
			}
			// Re-cache DOM
			$allCaptions = $el.find('.caption');
			// Load captions
			loadCaptions();
			// Show static caption
			captionContainerStatic.innerHTML = staticCaptionMessage;
			$captionStatic.css("opacity", "1");
		}
	}

	function initCaptionElement(i){
		// Create p element
		var caption = document.createElement('p');
		// Set attribute
		caption.setAttribute("id", "caption"+i);
		caption.setAttribute("class", "caption");
		// Convert to jQuery element
		var $caption = $(caption);
		// Push to array
		captions.push($caption);
		// Create span element
		var span = document.createElement('span');
		// Append child
		caption.append(span)
		// Push child to array
		captionContainer.push(span);
		// Append to DOM
		$el.append(caption);
	}

	function loadCaptions(){
		for (let i=0; i < audioElements.length-2; i++) {
			// Create track element
			var track = audioElements[i].textTracks[0];
			// Set mode
			track.mode = 'hidden';
			console.log(track);
			// Set event
			track.oncuechange = function(e) {
				var activeCue = this.activeCues[0];
				if (activeCue) {
					captionContainer[i].innerHTML = '';
					captionContainer[i].appendChild(activeCue.getCueAsHTML());
				}
			};
		}
	}

	function _render() {
		if (states.captionsOn){
			$el.show();
			closedCaptionsIcon.updateUI();
			chart.bumpChartTabs();
		} else {
			$el.hide();
			closedCaptionsIcon.updateUI();
			chart.bumpChartTabs();
		}
	}

	function showCaption(elm) {
		if (captionsSupported){
			$captionStatic.css("opacity", "0");
			captions[elm].css("opacity", "1");
		}
	}

	function hideCaption() {
		if (captionsSupported){
			$allCaptions.css("opacity", "0");
			$captionStatic.css("opacity", "1");
		}
	}

	function toggleCaptions() {
		states.captionsOn = !states.captionsOn;
		console.log("states.captionsOn", states.captionsOn);
		_render();
	}

	return {
		init: init,
		loadCaptions: loadCaptions,
		toggleCaptions: toggleCaptions,
		showCaption: showCaption,
		hideCaption: hideCaption
	};

})(); //closedCaptions

export {closedCaptions};