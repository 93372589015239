import {states} from './states.js';
import {audioContainer} from './audioContainer.js';
import {audioInfo} from './audioInfo.js';

// Marquee
var marquee = (function(){

	//data
	var marqueeMsgMaxCharacterLength = 49;
	var marqueeMsg = "";
	var defaultMarqueeMsg = "Rotate dial to tune radio";
	var remotePairedMarqueeMsg = "Rotate phone on a flat surface to tune radio";

	//cache DOM
	var $el = $('#marquee');

	//updateUI(defaultMarqueeMsg);

	function _render() {

		var fromStation = audioContainer.getFromStation();
		console.log("fromStation", fromStation);

		var fromPlaylistAdvance = audioContainer.getFromPlaylistAdvance();
		console.log("fromPlaylistAdvance", fromPlaylistAdvance);

		if (states.onStation && !states.onGhostStation && marqueeMsg.length > 1){
			if (!states.coverlayOpen){
				$el.css("opacity", "1");
				$el.addClass( "onStationPosition" );
				removeFlicker();
				audioInfo.removeFlicker();
			}
			if (fromPlaylistAdvance){
				// Set fromPlaylistAdvance
				audioContainer.setFromPlaylistAdvance(false);
				// Update message
				$el.html(marqueeMsg);
				// Init or destory marquee based on message character length
				if (marqueeMsg.length > marqueeMsgMaxCharacterLength){
					//destroyMarquee();
					initMarquee();
				}
				else {
					destroyMarquee();
				}
			}
		} else if (states.nearbyStation){
			if (!states.coverlayOpen){
				//$el.css("opacity", "1");
				$el.removeClass( "onStationPosition" );
				addFlicker();
				audioInfo.addFlicker();
			}
			if (!fromStation || fromPlaylistAdvance){
				// Set fromPlaylistAdvance
				audioContainer.setFromPlaylistAdvance(false);
				// Update message
				$el.html(marqueeMsg);
				// Init or destory marquee based on message character length
				if (marqueeMsg.length > marqueeMsgMaxCharacterLength){
					//destroyMarquee();
					initMarquee();
				}
				else {
					destroyMarquee();
				}
			}
		} else if ( (!states.onStation && !states.nearbyStation) || states.onGhostStation){
			if (!states.coverlayOpen){
				$el.css("opacity", ".5");
				$el.removeClass( "onStationPosition" );
				removeFlicker();
				audioInfo.removeFlicker();
			}
			if (states.remotePaired){
				marqueeMsg = remotePairedMarqueeMsg;
			} else {
				marqueeMsg = defaultMarqueeMsg;
			}
			// Update message
			$el.html(marqueeMsg);
		}
	}

	function fadeIn(){
		if ( (!states.onStation && !states.nearbyStation) || states.onGhostStation){
			$el.fadeTo(1200, 0.5);
		} else {
			$el.fadeTo(1200, 1);
		}
	}

	function fadeOut(){
		$el.fadeTo(1200, 0);
	}

	function addFlicker(){
		$el.addClass( "nearbyStationPosition" );
	}

	function removeFlicker(){
		$el.removeClass( "nearbyStationPosition" );
	}
	
	function initMarquee() {
		// jQueryMarquee
		$el.marquee({
			//speed in milliseconds of the marquee
			duration: 20000,
			//gap in pixels between the tickers
			gap: 50,
			//time in milliseconds before the marquee will start animating
			delayBeforeStart: 1500,
			//'left' or 'right'
			direction: 'left',
			//true or false - should the marquee be duplicated to show an effect of continues flow
			duplicated: true,
			//the marquee will be visible in the start if set to true
			startVisible: true
			//force the usage of jQuery's animate method even if the target browser supports CSS3 animations
			//allowCss3Support: false
		});
	}

	function destroyMarquee(){
		$el.marquee('destroy');
	}

	function updateUI(data) {
		if (data.length > 1){
			marqueeMsg = data;
		}
		_render();
	}

	return {
		updateUI: updateUI,
		fadeIn: fadeIn,
		fadeOut: fadeOut
	};

})(); //marquee

export {marquee};