import {states} from './states.js';
import {socketEmit} from './socketEmit.js';
import {coverlay} from './coverlay.js';
import {playButton} from './playButton.js';
import {audioContainer} from './audioContainer.js';
import {coverlayText} from './coverlayText.js';

// Ajax presentation
var ajaxPresentation = (function(){

	//data
	var currentAjaxModule;
	var currentAjaxTemplate;
	var ajaxHasScrollbar = false;
	var wasPlaying = false;

	//cache DOM
	var $el = $('#ajax-presentation');
	var $overlayOpen = $el.find('#overlayAjaxPresentationOpen');
	var $overlayClose = $el.find('#overlayAjaxPresentationClose');
	var $container = $el.find('#ajaxPresentationContainer');
	/*var $iframe = $('#iframeAjaxPresentationContainer');
	var $closeButton = $('#closeAjaxPresentationButton');*/
	// Note: jQuery used in _render() to target DOM in overlay.html

	//bind events
	// Note: closeAjaxPresentationButton onclick event used in overlay.html

	function _render() {

		var audioPlayingWithinFreqRange = audioContainer.getAudioPlayingWithinFreqRange();

		if (!states.ajaxPresentationOpen){

			socketEmit.send("returnCurrentAjaxTemplate", currentAjaxTemplate);

			states.ajaxPresentationOpen = true;
			console.log("states.ajaxPresentationOpen", states.ajaxPresentationOpen);
			socketEmit.state("ajaxPresentationOpen", states.ajaxPresentationOpen);
			
			// For volume fade
			/*if (states.playing){
				// Fade out audio based on radio position
				if (audioPlayingWithinFreqRange && !states.onSpatialAudioStation) {
					audioStatic.fadeOut();
					audioContainer.fadeOut();
				} else if (!states.onSpatialAudioStation){
					audioStatic.fadeOut();
				} else if (states.onSpatialAudioStation){
					// If omnitone is playing, call togglePlay();
					if (states.omnitonePlaying){
						playButton.togglePlay();
					}
				}
			}*/

			// If playing, stop playing and set wasPlaying to true
			if (states.playing){
				playButton.togglePlay();
				wasPlaying = true;
				console.log("wasPlaying", wasPlaying);
			} else {
				wasPlaying = false;
				console.log("wasPlaying", wasPlaying);
			}

			$container.show();
			$overlayOpen.fadeIn( 1000, function() {
				$container.loadHTML("overlay.html");
			});
		} else {

			// Override natural state of ajaxPresentationOpen variable to sync remote and desktop UI
			socketEmit.state("ajaxPresentationOpen", false);

			$overlayClose.fadeIn( 1000, function() {
				// Target DOM in overlay.html
				$( "#iframeAjaxPresentationContainer, #closeAjaxPresentationButton" ).remove();
				$container.hide();
				$overlayOpen.hide();
				$overlayClose.fadeOut(1000, function() {
					states.ajaxPresentationOpen = false;
					console.log("states.ajaxPresentationOpen", states.ajaxPresentationOpen);
				});

				// Close coverlay if open
				if (states.coverlayOpen){
					coverlay.toggleCoverlayFromAjax();
				}

				/*// For volume fade
				// Fade in audio based on radio position
				if (states.playing){
					if (states.onStation) {
						audioContainer.fadeIn();
					}
					else if (audioPlayingWithinFreqRange && !states.onStation) {
						audioStatic.fadeIn();
						audioContainer.fadeIn();
					}
					else if (!audioPlayingWithinFreqRange && !states.onStation) {
						audioStatic.fadeIn();
					}
				}*/

				// If not playing and wasPlaying is true, resume playing
				if (!states.playing && wasPlaying){
					playButton.togglePlay();
				}

			});
		}
	}

	// Ajax call for overlay module presentations
	// Source: https://www.jqueryscript.net/loading/Load-External-Files-Using-AJAX-jQuery-includeHTML.html
	$.fn.loadHTML = function (url) {
		var thisElement = this;
		$.ajax({
			type: 'GET',
			url: url,
			dataType: 'text',
			success: function(data) {
				$(thisElement).html(data);
			},
			error: function(e) { console.log(e.message); }
		});
	}

	function toggleAjaxPresentation(id, template, boolean) {
		var templateArray = [];
		currentAjaxModule = id;
		currentAjaxTemplate = template;
		ajaxHasScrollbar = boolean;
		// If currentAjaxTemplate is defined, split values into array
		// Note: will be undefined when closing ajax presentation
		if (currentAjaxTemplate){
			templateArray = currentAjaxTemplate.split(",");
		}
		// If template is defined as externalLink in database, open URL in a new tab. Otherwise, toggle ajax presentation.
		if (templateArray[0] == "externalLink"){
			window.open(templateArray[1], "_blank");
		} else {
			_render();
		}
		/*states.ajaxPresentationOpen = !states.ajaxPresentationOpen;
		console.log("states.ajaxPresentationOpen", states.ajaxPresentationOpen);	*/
	};

	function getCurrentAjaxModule() {
		return currentAjaxModule;
	}

	function getCurrentAjaxTemplate() {
		return currentAjaxTemplate;
	}

	function getAjaxHasScrollbar() {
		return ajaxHasScrollbar;
	}

	function triggerFromCoverlay() {
		var hasAjaxPresentation = coverlayText.detectAjaxPresentation();
		if (states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen && hasAjaxPresentation){
			// Target DOM in coverlay
			$( ".requestAjaxOverlay" ).trigger( "click" );
		}
	}

	return {
		toggleAjaxPresentation: toggleAjaxPresentation,
		getCurrentAjaxModule: getCurrentAjaxModule,
		getCurrentAjaxTemplate: getCurrentAjaxTemplate,
		getAjaxHasScrollbar: getAjaxHasScrollbar,
		triggerFromCoverlay: triggerFromCoverlay
	};

})(); //ajaxPresentation

//ajaxPresentation.toggleAjaxPresentation("module-name","template-name",true)

export {ajaxPresentation};