import io from 'socket.io-client';
const socket = io();
//const socket = io({transports: ['websocket'], upgrade: false});
import {stations} from '../database.js';
import {states} from './states.js';
import {options} from './options.js';
import {socketEmit} from './socketEmit.js';
import {qrCodeModal} from './qrCodeModal.js';
import {remoteStatus} from './remoteStatus.js';
import {playButton} from './playButton.js';
import {ajaxPresentation} from './ajaxPresentation.js';
import {chart} from './chart.js';
import {coverlay} from './coverlay.js';
import {phoneRotate} from './phoneRotate.js';
import {rightSideTabOmnitone} from './rightSideTabOmnitone.js';
import {passwordProtectForm} from './passwordProtectForm.js';
import {moveToStation} from './moveToStation.js';
import {coverlayText} from './coverlayText.js';
import {radioKnobController} from './radioKnobController.js';

// Send deviceType after server recieves initial connection
socket.on('deviceConnect', function(){
	socket.emit('deviceType', 'desktop', function(data){
		// Callback message
		console.log(data);
	});
});

// Desktop joins room and displays QR code
socket.on('connectToRoom',function(roomno){
	console.log('Socket ID Connected: '+socket.id);
	console.log("Connected to room-"+roomno);
	states.socketRoomCode = roomno;
	console.log("states.socketRoomCode", states.socketRoomCode);
	if (!options.passwordProtect){
		qrCodeModal.init(states.socketRoomCode);
	}
});

// Mobile joins room
socket.on('connectMobile',function(){
	console.log("Mobile or app connected");
	// Fix remote status icon display if mobile remote site is refreshed, which causes a rapid disconnect and reconnect
	setTimeout(function(){
		states.remotePaired = true;
		console.log("states.remotePaired", states.remotePaired);
		remoteStatus.updateUI();
	}, 500);
});

// Mobile leaves room
socket.on('disconnectMobile',function(){
	console.log("Lost connection with mobile browser or app");
	states.remotePaired = false;
	console.log("states.remotePaired", states.remotePaired);
	remoteStatus.updateUI();	
	// Remove socket from socket array, leave room, generate new room
	if (options.newSocketRoomOnRemoteDisconnect){
		console.log("Leave room");
		socket.emit('leaveRoom');
		console.log("Generate new room");
		socket.emit('deviceType', 'desktop', function(data){
			// Callback message
			console.log(data);
		});
	}
});

// Request current desktop states
socket.on('requestDesktopStates',function(){
	socketEmit.returnDesktopStates();
});

// Request hasAjaxPresentation
socket.on('requestHasAjaxPresentation',function(){
	var hasAjaxPresentation = coverlayText.detectAjaxPresentation();
	socketEmit.send("returnHasAjaxPresentation", hasAjaxPresentation);
});

// Request currentAjaxTemplate
socket.on('requestCurrentAjaxTemplate',function(){
	var currentAjaxTemplate = ajaxPresentation.getCurrentAjaxTemplate();
	socketEmit.send("returnCurrentAjaxTemplate", currentAjaxTemplate);
});

// Button tap
socket.on('buttonTap',function(data){
	if (data == "togglePlay" && !states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen){
		playButton.togglePlay();
	} else if (data == "triggerAjaxPresentationFromCoverlay"){
		ajaxPresentation.triggerFromCoverlay();
	} else if (data == "stationChartTabTrigger"){
		chart.stationChartTabTrigger();
	} else if (data == "toggleCoverlay"){
		if (!states.qrCodeModalOpen && !states.ajaxPresentationOpen){
			coverlay.toggleCoverlay();
		}
	} else if (data == "toggleAjaxPresentation"){
		ajaxPresentation.toggleAjaxPresentation();
	} /*else if (data == "moveToAboutStation"){
		wordmark.moveToAboutStation();
	}*/
});

// Remote orientation
socket.on('orientation',function(data){
	console.log("orientation", data);
	// If remote orientation switches to phoneHeld and channel is within nearby station frequency range, then go to closest station except ghost stations
	if (data === "phoneHeld"){
		var channel = radioKnobController.getChannel();
		console.log("channel", channel);
		for (var i=0; i < stations.length-2; i++) {
			if (channel >= Math.round((stations[i].freq-0.5) * 100) / 100 && channel <= Math.round((stations[i].freq+0.5) * 100) / 100){
				var closestStation = Math.round((stations[i].freq) * 100) / 100;
				console.log("closestStation", closestStation);
				moveToStation.startPhoneHeld(closestStation);
			}
		}
	}
});

// Phone rotate angleDifference
socket.on('angleDifference',function(data){
	if (!states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen && !states.enterPageOpen && !states.autoTuning){
		phoneRotate.angleDifference(data);
	} else if (states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen && !states.enterPageOpen && !states.autoTuning){
		coverlay.scrollWrapCoverlay(data);
	}
});

// Phone rotate angleSpatialAudio
socket.on('angleSpatialAudio',function(data){
	console.log("angleSpatialAudio", data);
	rightSideTabOmnitone.rotateAzimuthControlKnob(data);
});

// Response from server after password protect form input is checked
socket.on('checkFormPasswordResponse', function(data){
	passwordProtectForm.updateUI(data);
});

export {socket};