import {states} from './states.js';

// Audio info
var audioInfo = (function(){

	//data
	var audioInfoMsg = "";

	//cache DOM
	var $el = $('#audio-info');

	function _render() {
		if (states.onStation && !states.onGhostStation && audioInfoMsg.length > 1){
			$el.html(audioInfoMsg);
		} else if (states.nearbyStation){
			$el.html(audioInfoMsg);
		} else if ( (!states.onStation && !states.nearbyStation) || states.onGhostStation){
			$el.html("");
		}
	}

	function fadeIn(){
		$el.fadeTo(1200, 1);
	}

	function fadeOut(){
		$el.fadeTo(1200, 0);
	}

	function addFlicker(){
		$el.addClass( "nearbyStationPosition" );
	}

	function removeFlicker(){
		$el.removeClass( "nearbyStationPosition" );
	}

	function updateUI(data) {
		if (data.length > 1){
			audioInfoMsg = data;
		}
		_render();
	}

	return {
		updateUI: updateUI,
		fadeIn: fadeIn,
		fadeOut: fadeOut,
		addFlicker: addFlicker,
		removeFlicker: removeFlicker
	};

})(); //audioInfo

export {audioInfo};