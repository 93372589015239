import {states} from './states.js';
import {socket} from './socketListen.js';

// Socket emit
var socketEmit = (function(){

	function state(name, status) {
		//console.log("state", name, status);
		socket.emit('state', name, status);
	}

	function returnDesktopStates() {
		var desktopStates = {
			onStation: states.onStation,
			playing: states.playing,
			coverlayOpen : states.coverlayOpen,
			ajaxPresentationOpen : states.ajaxPresentationOpen,
			chartOpenStationChartActive: states.chartOpenStationChartActive
		};
		socket.emit('returnDesktopStates', desktopStates);
	}

	function send(event, msg){
		if (msg){
			socket.emit(event, msg);
		} else {
			socket.emit(event);
		}
	}

	return {
		state: state,
		returnDesktopStates: returnDesktopStates,
		send: send
	};

})(); //socketEmit

export{socketEmit};