// Note: linked in HTML markup, not working here
//const Omnitone = require('omnitone');
//import Omnitone from 'omnitone';
import {S3_PATH} from '../config.js';
import {spatialAudioAmbixSrcLink} from '../database.js';
import {states} from './states.js';
import {options} from './options.js';

// OmnitoneHOA
var omnitoneHOA = (function(){

	// Source: https://github.com/GoogleChrome/omnitone

	//data
	var loaded = false;

	'use strict';

	var exampleInitialized = false;

	var audioContext;
	var toaRenderer;
	var soaRenderer;
	var currentBufferSource;
	var inputGain;
	var contentBuffer;
	var rotationMatrix3 = new Float32Array(9);

	var eButtonPlayback;
	var eButtonAmbisonicOrder;
	var eRangeAzimuth;
	var eRangeElevation;
	var eRangeGain;

	//mod
	var azimuthValue;
	var num;
	var mappedNum;

	//mod
	var exampleSoundPathList = [
		S3_PATH + spatialAudioAmbixSrcLink
	];

	//mod
	// Mapping number ranges
	// Source: https://gist.github.com/xposedbones/75ebaef3c10060a3ee3b246166caab56
	Number.prototype.map = function (in_min, in_max, out_min, out_max) {
		return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
	};

	function crossProduct(a, b) {
		return [
			a[1] * b[2] - a[2] * b[1],
			a[2] * b[0] - a[0] * b[2],
			a[0] * b[1] - a[1] * b[0]
		];
	}

	function normalize(a) {
		var n = Math.sqrt(a[0] * a[0] + a[1] * a[1] + a[2] * a[2]);
			a[0] /= n;
			a[1] /= n;
			a[2] /= n;
		return a;
	}

	function onDirectionChange() {
		if (!exampleInitialized)
			return;

		//mod
		// Map number ranges
		num = parseFloat(document.getElementById('eRangeAzimuth').value);
		mappedNum = num.map(0, 360, -180, 180);
		console.log("mappedNum = "+mappedNum);
		azimuthValue = mappedNum;

		/*var azimuthValue =
			parseFloat(document.getElementById('eRangeAzimuth').value);*/ //mod
		var elevationValue =
			parseFloat(document.getElementById('eRangeElevation').value);
		document.getElementById('eLabelAzimuth').textContent = azimuthValue;
		document.getElementById('eLabelElevation').textContent = elevationValue;

		// Standard OpenGL-style "View" Matrix calculation.
		var theta = azimuthValue / 180 * Math.PI;
		var phi = elevationValue / 180 * Math.PI;
		var forward = [
			Math.sin(theta) * Math.cos(phi),
			Math.sin(phi),
			Math.cos(theta) * Math.cos(phi)
		];
		var upInitial = [0, 1, 0];
		var right = normalize(crossProduct(forward, upInitial));
		var up = normalize(crossProduct(right, forward));

		rotationMatrix3[0] = right[0];
		rotationMatrix3[1] = right[1];
		rotationMatrix3[2] = right[2];
		rotationMatrix3[3] = up[0];
		rotationMatrix3[4] = up[1];
		rotationMatrix3[5] = up[2];
		rotationMatrix3[6] = forward[0];
		rotationMatrix3[7] = forward[1];
		rotationMatrix3[8] = forward[2];

		eButtonAmbisonicOrder.textContent === '3rd Order'
			? toaRenderer.setRotationMatrix3(rotationMatrix3)
			: soaRenderer.setRotationMatrix3(rotationMatrix3);
	}

	function onGainSliderChange() {
		if (!exampleInitialized)
			return;

		document.getElementById('eLabelGain').textContent = eRangeGain.value;
			inputGain.gain.value = Math.pow(10, parseFloat(eRangeGain.value) / 20);
	}

	function onTogglePlayback(event) {
		if (!exampleInitialized)
			return;

		switch (event.target.textContent) {
			case 'Play':
				currentBufferSource = audioContext.createBufferSource();
				currentBufferSource.buffer = contentBuffer;
				currentBufferSource.loop = true;
				currentBufferSource.connect(inputGain);
				currentBufferSource.start();
				event.target.textContent = 'Stop';
				break;
			case 'Stop':
				currentBufferSource.stop();
				currentBufferSource.disconnect();
				event.target.textContent = 'Play';
				break;
		}
	}

	//mod
	function playOmnitone() {
		if (!exampleInitialized)
			return;

		if (!states.omnitonePlaying){
			states.omnitonePlaying = true;
			console.log("states.omnitonePlaying", states.omnitonePlaying);
			currentBufferSource = audioContext.createBufferSource();
			currentBufferSource.buffer = contentBuffer;
			currentBufferSource.loop = true;
			currentBufferSource.connect(inputGain);
			currentBufferSource.start();
		}
	}

	//mod
	function stopOmnitone() {
		if (!exampleInitialized)
			return;

		if (states.omnitonePlaying){
			states.omnitonePlaying = false;
			console.log("states.omnitonePlaying", states.omnitonePlaying);
			currentBufferSource.stop();
			currentBufferSource.disconnect();
		}
	}

	function onToggleAmbisonicOrder(event) {
		if (!exampleInitialized)
			return;

		switch (event.target.textContent) {
			case '3rd Order':
				toaRenderer.setRenderingMode('off');
				soaRenderer.setRenderingMode('ambisonic');
				soaRenderer.setRotationMatrix3(rotationMatrix3);
				event.target.textContent = '2nd Order';
				break;
			case '2nd Order':
				soaRenderer.setRenderingMode('off');
				toaRenderer.setRenderingMode('ambisonic');
				toaRenderer.setRotationMatrix3(rotationMatrix3);
				event.target.textContent = '3rd Order';
				break;
		}
	}

	function onLoad() {
		eButtonPlayback = document.getElementById('eButtonPlayback');
		eButtonAmbisonicOrder =
			document.getElementById('eButtonAmbisonicOrder');
		eRangeAzimuth = document.getElementById('eRangeAzimuth');
		eRangeElevation = document.getElementById('eRangeElevation');
		eRangeGain = document.getElementById('eRangeGain');

		eButtonPlayback.onclick = onTogglePlayback;
		eButtonAmbisonicOrder.onclick = onToggleAmbisonicOrder;
		eRangeGain.oninput = onGainSliderChange;
		eRangeAzimuth.oninput = onDirectionChange;
		eRangeElevation.oninput = onDirectionChange;

		audioContext = new AudioContext();
		inputGain = audioContext.createGain();
		toaRenderer = Omnitone.createHOARenderer(audioContext);
		soaRenderer = Omnitone.createHOARenderer(audioContext, 
												{ambisonicOrder: 2});

		Promise.all([
			Omnitone.createBufferList(audioContext, exampleSoundPathList),
			toaRenderer.initialize(),
			soaRenderer.initialize()
			]).then((results) => {
				contentBuffer =
					Omnitone.mergeBufferListByChannel(audioContext, results[0]);
				inputGain.connect(soaRenderer.input);
				inputGain.connect(toaRenderer.input);
				soaRenderer.output.connect(audioContext.destination);
				toaRenderer.output.connect(audioContext.destination);
				soaRenderer.setRenderingMode('off');
				eButtonPlayback.disabled = false;
				eButtonAmbisonicOrder.disabled = false;
				exampleInitialized = true;
				//mod
				// Create element for loader
				var omnitoneLoadedElm = document.createElement("div");
				omnitoneLoadedElm.setAttribute("id", "omnitoneLoaded");
    			document.body.appendChild(omnitoneLoadedElm);
			});
	}

	window.addEventListener('load', init); //mod

	function init(){
		if (!loaded && !options.disableOmnitone){
			loaded = true;
			console.log("loaded", loaded);
			onLoad(); //mod
		} else if (options.disableOmnitone){
			// Create element for loader
			var omnitoneLoadedElm = document.createElement("div");
			omnitoneLoadedElm.setAttribute("id", "omnitoneLoaded");
			document.body.appendChild(omnitoneLoadedElm);
		}
	}

	//mod
	return {
		init: init,
		onDirectionChange: onDirectionChange,
		playOmnitone: playOmnitone,
		stopOmnitone: stopOmnitone
	};

})(); //omnitoneHOA

export {omnitoneHOA};