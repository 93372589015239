import {states} from './states.js';
import {socketEmit} from './socketEmit.js';
import {enterPage} from './enterPage.js';
import {qrCodeModal} from './qrCodeModal.js';

// Password protect form
var passwordProtectForm = (function(){

	//states
	var passwordProtectFormOpen = false;

	//data
	var loaded = false;
	var passwordCorrect = false;
	var formInput;
	var response;
	var fadeInDuration = 1000;
	var fadeOutDuration = 1000;

	//cache DOM
	var $el = $('#password-protect-form');
	var inputDomEl = $el.find('input')[0];
	var $button = $el.find('button');
	var $response = $el.find('p#passwordCheckResponse');
	var responseDomEl = $el.find('p#passwordCheckResponse')[0];

	//bind events
	$button.on('click', checkPassword);

	function init() {
		passwordProtectFormOpen = true;
		console.log("passwordProtectFormOpen", passwordProtectFormOpen);
		fadeIn();
	}

	function _render() {
		if (passwordCorrect) {
			// Disable button
			$button.addClass("avoid-clicks");
			$response.css("color", "green");
			response = "Password correct";
			fadeOut();
		} else {
			$response.css("color", "red");
			response = "Password incorrect";
			inputDomEl.value = '';
			inputDomEl.focus();
		}
		responseDomEl.innerHTML = response;
	}

	function checkPassword() {
		formInput = inputDomEl.value;
		socketEmit.send('checkFormPassword', formInput);
	}

	function fadeIn() {
		if (!loaded){
			loaded = true;
			console.log("loaded", loaded);
			$el.fadeIn(fadeInDuration);
		}
	}

	function fadeOut() {
		$el.fadeOut(fadeOutDuration, function() {
			enterPage.fadeIn();
			qrCodeModal.init(states.socketRoomCode);
			passwordProtectFormOpen = false;
			console.log("passwordProtectFormOpen", passwordProtectFormOpen);
		});
	}

	function getPasswordCorrect() {
		return passwordCorrect;
	}

	function getPasswordProtectFormOpen() {
		return passwordProtectFormOpen;
	}

	function updateUI(data) {
		if (data === "correct") {
			passwordCorrect = true;
		} else if (data === "incorrect") {
			passwordCorrect = false;
		}
		_render();
	}

	return {
		init: init,
		updateUI: updateUI,
		checkPassword: checkPassword,
		getPasswordCorrect: getPasswordCorrect,
		getPasswordProtectFormOpen: getPasswordProtectFormOpen
	};

})(); //passwordProtectForm

export {passwordProtectForm};