import {states} from './states.js';
import {seekStations} from './seekStations.js';
import {chart} from './chart.js';
import {closedCaptions} from './closedCaptions.js';
import {playButton} from './playButton.js';
import {coverlay} from './coverlay.js';
import {coverlayText} from './coverlayText.js';
import {qrCodeModal} from './qrCodeModal.js';
import {ajaxPresentation} from './ajaxPresentation.js';
import {passwordProtectForm} from './passwordProtectForm.js';

// Keyboard commands
var keyboardCommands = (function(){

	//data
	var keyboardEnable = true;
	var keyboardTrigger = false;

	// Source: https://stackoverflow.com/questions/1402698/binding-arrow-keys-in-js-jquery
	$(document).keydown(function(e) {
		if(keyboardEnable && !states.enterPageOpen) {
			if(!keyboardTrigger) {
				keyboardTrigger = true;
				switch(e.which) {
					case 37:
						console.log("Left arrow pressed");
						var stationChartActive = chart.getStationChartActive();
						if (!states.chartOpen && !states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen && !states.autoTuning){
							seekStations.prevStation();
						} else if (states.chartOpen && !states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen && stationChartActive){
							chart.operatingInstructionsTabTrigger();
						}
						break;

					case 39:
						console.log("Right arrow pressed");
						var stationChartActive = chart.getStationChartActive();
						if (!states.chartOpen && !states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen && !states.autoTuning){
							seekStations.nextStation();
						} else if (states.chartOpen && !states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen && !stationChartActive){
							chart.stationChartTabTrigger();
						}
						break;

					case 38:
						console.log("Up arrow pressed");
						if (!states.chartOpen && !states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen){
							chart.toggleChartActiveTab();
						}
						break;

					case 40:
						console.log("Down arrow pressed");
						if (states.chartOpen && !states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen){
							chart.toggleChart();
						}
						break;

					case 67:
						console.log("c key pressed");
						if (!states.ajaxPresentationOpen){
							closedCaptions.toggleCaptions();
						}
						break;

					case 32:
						console.log("Spacebar key pressed");
						if (!states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen){
							playButton.togglePlay();
						}
						break;

					case 77:
						console.log("m key pressed");
						if (!states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen){
							playButton.togglePlay();
						}
						break;	

					case 73:
						console.log("i key pressed");
						if (states.onStation && !states.qrCodeModalOpen && !states.ajaxPresentationOpen){
							coverlay.toggleCoverlay();
						}
						break;	

					case 27:
						console.log("ESC key pressed");
						if (states.chartOpen && !states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen){
							chart.toggleChart();
						} else if (states.coverlayOpen && states.onStation && !states.qrCodeModalOpen && !states.ajaxPresentationOpen){
							coverlay.toggleCoverlay();
						} else if (states.ajaxPresentationOpen){
							// Target DOM in ajax presentation
							$( "#closeAjaxPresentationButton" ).trigger( "click" );
						} else if (!states.coverlayOpen && states.qrCodeModalOpen && !states.ajaxPresentationOpen){
							qrCodeModal.toggleQrCodeModal();
						}
						break;

					case 13:
						console.log("Enter key pressed");
						var hasAjaxPresentation = coverlayText.detectAjaxPresentation();
						if (states.onStation && !states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen){
							coverlay.toggleCoverlay();
						} else if (states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen && hasAjaxPresentation){
							ajaxPresentation.triggerFromCoverlay();
						}
						break;

					default: return; // Exit this handler for other keys
				}
				e.preventDefault(); // Prevent the default action
			}
		}
		else if (keyboardEnable && states.enterPageOpen && passwordProtectForm.getPasswordProtectFormOpen()){
			if(!keyboardTrigger) {
				keyboardTrigger = true;
				switch(e.which) {
					case 13:
						console.log("Enter key pressed");
						passwordProtectForm.checkPassword();
						break;

					default: return; // Exit this handler for other keys
				}
				e.preventDefault(); // Prevent the default action
			}
		}
	});

	$(document).keyup(function(e) {
		keyboardTrigger = false;
	});

	function toggleKeyboardEnable(){
		keyboardEnable = !keyboardEnable;
		console.log("keyboardEnable", keyboardEnable);
	}

	return {
		toggleKeyboardEnable: toggleKeyboardEnable
	};

})(); //keyboardCommands

export {keyboardCommands};