// Operating instructions
var operatingInstructions = (function(){

	//data
	var instructions = [
		"Click the icon (then scan QR code) to control the radio with your phone",
		"Use your phone or cursor to turn the dial (or use keyboard arrows)",
		"Read more about a story (a red “i” means you can access more media)",
		"Toggle to play or pause sound",
		"Search for a specific story by name or keyword",
		"On the Station Chart, see stories now playing and where to find them"
	];

	//cache DOM
	var $el = $('#operatingInstructions');
	var $img = $el.find('.imageColumn img');
	var instructionElements = [
		$el.find('li.instruction1 p'),
		$el.find('li.instruction2 p'),
		$el.find('li.instruction3 p'),
		$el.find('li.instruction4 p'),
		$el.find('li.instruction5 p'),
		$el.find('li.instruction6 p')
	];

	function init(){

		// Detect macOS
		var macOS = bowser.osname == "macOS";

		// Load main image
		// Only load svg if MacOS is detected to resolve different OS default fonts
		if (macOS){
			console.log("MacOS detected");
			$img.attr("src","images/instructions/operating-instructions.svg");
		} else {
			$img.attr("src","images/instructions/operating-instructions.png");
		}

		// Load number images
		// Get alt value and create source image path for instruction numbers
		$('.listColumn img').each(function(){
			var fileName = $(this).attr('alt');
			console.log("fileName", fileName);
			// Only load svg if MacOS is detected to resolve different OS default fonts
			if (macOS){
				//console.log("MacOS detected");
				$(this).attr("src","images/instructions/"+fileName+".svg");
			} else {
				$(this).attr("src","images/instructions/"+fileName+".png");
			}
		});

		// Load text
		for (let i=0; i < instructionElements.length; i++) {
			instructionElements[i].html(instructions[i]);
		}
	}

	return {
		init: init
	}

})(); //operatingInstructions

export {operatingInstructions};