import {productionMode} from '../config.js';

var options = {
	passwordProtect: false,
	newSocketRoomOnRemoteDisconnect: false,
	disableShareButtons: false,
	hideShareButtons: false,
	disableOmnitone: false,
	disableMouseScroll: false,
	disableRightSideTabMessage: false,
	stickyStations: true,
	volumeAudio: 1 // Max volume of audio stations and static. Use for a volume slider.
};

if (productionMode !== 'true') {
	options.disableShareButtons = true;
}

export {options};