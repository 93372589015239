import {stations} from '../database.js';
import {states} from './states.js';
import {options} from './options.js';
import {socketEmit} from './socketEmit.js';
import {radioKnobController} from './radioKnobController.js';
import {audioContainer} from './audioContainer.js';
import {audioStatic} from './audioStatic.js';
import {omnitoneHOA} from './omnitoneHOA.js';

// Play button
var playButton = (function(){

	//cache DOM
	var $el = $('#play-button');
	var $link = $el.find('a');
	var $image = $el.find('img');

	//bind events
	$link.on('click', togglePlay);

	function init(){
		_render();
	}

	function _render() {
		if (states.playing){
			$image.attr("src","images/icons/pause.svg");
		} else {
			$image.attr("src","images/icons/play.svg");
		}
	}

	function togglePlay() {

		var channel = radioKnobController.getChannel();
		console.log("channel", channel);

		if (states.playing) {
			states.playing = false;
			console.log("states.playing", states.playing);
			socketEmit.state("playing", states.playing);
			audioContainer.setVolume(0);
			for (var i=0; i < stations.length; i++) {
				document.getElementById("id"+i).pause();
			}
			// If omnitone is playing, stop omnitone
			if (states.omnitonePlaying){
				omnitoneHOA.stopOmnitone();
			}
		} else {
			states.playing = true;
			console.log("states.playing", states.playing);
			socketEmit.state("playing", states.playing);
			// If togglePlay is called from enter page and the channel is 1, then fade in audio static
			if (states.enterPageOpen && channel == 1){
				audioStatic.fadeIn(1500);
			} else {
				audioContainer.setVolume(options.volumeAudio);
			}
			for (var i=0; i < stations.length; i++) {
				// Only resume audio pieces that have been triggered by dial or if channel is within frequency range of station
				if (document.getElementById("id"+i).currentTime > 0 || (channel >= Math.round((stations[i].freq-0.5) * 100) / 100 && channel <= Math.round((stations[i].freq+0.5) * 100) / 100))  {
					// If channel is not approaching or on spatial audio station, then play
					if (i != 4){
						document.getElementById("id"+i).play();
					}
				}
			}
			// Play omnitone if on spatial audio station
			if (states.onSpatialAudioStation && !states.omnitonePlaying){
				audioContainer.restartSpatialAudio();
				omnitoneHOA.playOmnitone();
				// Play null audio for audio info update
				document.getElementById("id4").play();
			}
		}
		_render();
	};

	return {
		init: init,
		togglePlay: togglePlay
	};

})(); //playButton

export {playButton};