import {enterPage} from './enterPage.js';
import {radioKnobController} from './radioKnobController.js';
import {overlayPreventClick} from './overlayPreventClick.js';

// App
var app = (function(){

	//cache DOM
	var $el = $('#app');

	function fadeIn(){
		//$el.show();
		$el.addClass('visible');
		$el.fadeTo(1000, 1, function() {
			radioKnobController.findCenter();
			overlayPreventClick.hideOverlay();
			enterPage.setEnterPageOpen(false);
		});
	}

	return {
		fadeIn: fadeIn
	};

})(); //app

export {app};