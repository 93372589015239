// Webfont
/*var WebFont = require('webfontloader');
WebFont.load({
	typekit: {
		id: 'enf7kno'
	},
	google: {
		families: ['Wire One']
	}
});*/

// CSS
import './assets/css/desktop/style_global.css';
import './assets/css/desktop/style_app.css';
//import './assets/css/desktop/style_wordmark-text.css';
//import './assets/css/desktop/style_wordmark-image.css';
import './assets/css/desktop/style_qr-code-modal.css';
import './assets/css/desktop/style_spotlight-icon.css';
import './assets/css/desktop/style_closed-captions-icon.css';
import './assets/css/desktop/style_closed-captions.css';
import './assets/css/desktop/style_coverlay.css';
import './assets/css/desktop/style_coverlay-share-buttons.css';
import './assets/css/desktop/style_radio.css';
import './assets/css/desktop/style_radio-knob.css';
import './assets/css/desktop/style_radio-knob-controller.css';
import './assets/css/desktop/style_radio-push-buttons.css';
import './assets/css/desktop/style_radio-tuner.css';
import './assets/css/desktop/style_radio-tuner-marquee.css';
import './assets/css/desktop/style_radio-tuner-audio-info.css';
import './assets/css/desktop/style_radio-tuner-notches.css';
import './assets/css/desktop/style_chart.css';
import './assets/css/desktop/style_chart-tabs.css';
import './assets/css/desktop/style_chart-content.css';
import './assets/css/desktop/style_left-side-tab-menu.css';
import './assets/css/desktop/style_right-side-tab-message.css';
import './assets/css/desktop/style_right-side-tab-omnitone.css';
import './assets/css/desktop/style_enter-page.css';
import './assets/css/desktop/style_overlay-prevent-click.css';
import './assets/css/desktop/style_remote-status.css';
import './assets/css/desktop/style_password-protect-form.css';
import './assets/css/desktop/style_ajax-presentation.css';
import './assets/css/desktop/style_browser-error-message.css';
// jsSocials
import '../node_modules/jssocials/dist/jssocials.css';
import '../node_modules/jssocials/dist/jssocials-theme-minima.css';

// Needed for ajax presentation
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Vendors
import 'jquery.easing';
import 'jquery.marquee';
import 'jssocials';
import 'jqueryui';
import 'css-browser-selector';

// JS
import './assets/js/jquery.knob_mod.js';
import './assets/js/jQueryRotateCompressed.js';
import './assets/js/desktop/desktop.js';