import {states} from './states.js';
import {socketEmit} from './socketEmit.js';

// Chart
var chart = (function(){

	//states
	var stationChartActive = false;

	//data
	var chartAnimationComplete = true;
	var chartAnimationDuration = 750;
	var bumpChartTabsAnimationDuration = 750;
	var autoHeightAnimationDuration = 800;
	var windowHeightForBumpChartTabs = 704;

	//cache DOM
	var $el = $('#chart');
	var $push = $el.find('#chart-push');
	var $overlay = $el.find('#chart-overlay');
	var $tabs = $el.find('#chart-tabs');
	var $tabItems = $tabs.find('.tabs li');
	var $tabStationChart = $tabs.find('#stationChartTab');
	var $tabOperatingInstructions = $tabs.find('#operatingInstructionsTab');
	var $content = $el.find('#chart-content');
	var $stationChart = $el.find('#stationChart');
	var $operatingInstructions = $el.find('#operatingInstructions');

	//bind events
	$overlay.on('click', toggleChart);
	$content.on('click', toggleChart);
	$tabItems.on('mouseenter', _renderActiveTab);
	//$tabItems.on('click', _renderActiveTab);
	$tabStationChart.on('click', stationChartTabTrigger);
	$tabOperatingInstructions.on('click', operatingInstructionsTabTrigger);
	window.addEventListener('resize', resizeChart);

	//_render();

	function _render() {

		if (!states.chartOpen){
			chartAnimationComplete = false;
			console.log("chartAnimationComplete", chartAnimationComplete);
			_renderActiveContent();
			// Change z-index of app elements to address a Safari layer issue
			/*document.querySelector('.global-radio').style.zIndex = "-1";
			document.querySelector('#WrapQRcode').style.zIndex = "-1";
			document.querySelector('#WrapPhoneLink').style.zIndex = "-1";
			document.querySelector('#wordmark').style.zIndex = "-1";*/
			// Animate push element
			$push.animate({
				marginBottom: '0px'
			}, chartAnimationDuration, "easeInOutQuart", function() {
			});
			// Animate chart-content element
			$content.animate({
				height: $content.get(0).scrollHeight,
				marginBottom: '0px'
			}, chartAnimationDuration, "easeInOutQuart", function() {
				$(this).height('auto');
				// Show overlay
				$overlay.show();
				// Resize chart
				autoHeightAnimate($content, autoHeightAnimationDuration);
				chartAnimationComplete = true;
				console.log("chartAnimationComplete", chartAnimationComplete);
			});
		} else {
			chartAnimationComplete = false;
			console.log("chartAnimationComplete", chartAnimationComplete);
			states.chartOpenStationChartActive = false;
			console.log("states.chartOpenStationChartActive", states.chartOpenStationChartActive);
			socketEmit.state("chartOpenStationChartActive", states.chartOpenStationChartActive);
			// Hide overlay
			$overlay.hide();
			// If captions are on and window height is greater then windowHeightForBumpChartTabs
			var windowHeightVar = $(window).height();
			if(states.captionsOn && windowHeightVar > windowHeightForBumpChartTabs) {
				var pushMarginBottom = "580px";
				var contentMarginBottom = "-400px";
			}
			else{
				var pushMarginBottom = "552px";
				var contentMarginBottom = "-460px";
			}
			// Animate push element
			$push.animate({
				marginBottom: pushMarginBottom
			}, chartAnimationDuration, "easeInOutQuart", function() {
			});
			// Animate chart-content element
			$content.animate({
				height: '60px',
				marginBottom: contentMarginBottom
			}, chartAnimationDuration, "easeInOutQuart", function() {
				// Change z-index of app elements to address a Safari layer issue
				/*document.querySelector('.global-radio').style.zIndex = "10";
				document.querySelector('#WrapQRcode').style.zIndex = "11";
				document.querySelector('#WrapPhoneLink').style.zIndex = "11";
				document.querySelector('#wordmark').style.zIndex = "11";*/
				chartAnimationComplete = true;
				console.log("chartAnimationComplete", chartAnimationComplete);
			});
		}
	}

	function _renderActiveTab(event) {
		if (event.type == 'mouseenter') {
			if (!states.chartOpen && chartAnimationComplete){
				$tabItems.removeClass("active");
				$(this).addClass("active");
			}
		}
		/*else if (event.type == 'click') {
			if (states.chartOpen && chartAnimationComplete){
				$tabItems.removeClass("active");
				$(this).addClass("active");
			}
		}*/
		else if (event == 'function') {
			if (stationChartActive){
				$tabItems.removeClass("active");
				$tabStationChart.addClass("active");
			}
			else {
				$tabItems.removeClass("active");
				$tabOperatingInstructions.addClass("active");
			}
		}
	}

	function _renderActiveContent(){
		if (stationChartActive) {
			$stationChart.show();
			$operatingInstructions.hide();
			states.chartOpenStationChartActive = true;
			console.log("states.chartOpenStationChartActive", states.chartOpenStationChartActive);
			socketEmit.state("chartOpenStationChartActive", states.chartOpenStationChartActive);

		} else {
			$stationChart.hide();
			$operatingInstructions.show();
			states.chartOpenStationChartActive = false;
			console.log("states.chartOpenStationChartActive", states.chartOpenStationChartActive);
			socketEmit.state("chartOpenStationChartActive", states.chartOpenStationChartActive);
		}
		_renderActiveTab('function');
	}

	function stationChartTabTrigger() {
		if (!states.chartOpen && chartAnimationComplete) {
			stationChartActive = true;
			console.log("stationChartActive", stationChartActive);
			toggleChart();
		}
		else if (states.chartOpen && chartAnimationComplete && stationChartActive) {
			toggleChart();
		}
		else if (states.chartOpen && chartAnimationComplete && !stationChartActive) {
			stationChartActive = true;
			console.log("stationChartActive", stationChartActive);
			_renderActiveContent();
			autoHeightAnimate($content, autoHeightAnimationDuration);
		}
	}

	function operatingInstructionsTabTrigger() {
		if (!states.chartOpen && chartAnimationComplete) {
			stationChartActive = false;
			console.log("stationChartActive", stationChartActive);
			toggleChart();
		}
		else if (states.chartOpen && chartAnimationComplete && !stationChartActive) {
			toggleChart();
		}
		else if (states.chartOpen && chartAnimationComplete && stationChartActive) {
			stationChartActive = false;
			console.log("stationChartActive", stationChartActive);
			_renderActiveContent();
			autoHeightAnimate($content, autoHeightAnimationDuration);
		}
	}

	// Source: https://codepen.io/JTParrett/pen/CAglw
	/* Function to animate height: auto */
	function autoHeightAnimate(element, time){
		var curHeight = element.height(), // Get Default Height
		autoHeight = element.css('height', 'auto').height(); // Get Auto Height
		element.height(curHeight); // Reset to Default Height
		element.stop().animate({
			height: autoHeight // Animate to Auto Height
		}, time, "easeInOutQuart", function() {
		});
	}

	function resizeChart() {
		if (states.chartOpen && chartAnimationComplete){
			autoHeightAnimate($content, autoHeightAnimationDuration);
		}
	}

	function bumpChartTabs(){
		if (states.captionsOn){
			var windowHeightVar = $(window).height();
			if(!states.chartOpen && windowHeightVar > windowHeightForBumpChartTabs) {
				var pushMarginBottom = "580px";
				var contentMarginBottom = "-400px";

				$push.animate({
					marginBottom: pushMarginBottom
				}, bumpChartTabsAnimationDuration, "easeInOutQuart", function() {
				});
				$content.animate({
					marginBottom: contentMarginBottom
				}, bumpChartTabsAnimationDuration, "easeInOutQuart", function() {
				});
			}
		} else {
			var pushMarginBottom = "552px";
			var contentMarginBottom = "-460px";

			if(!states.chartOpen) {
				$content.animate({
					marginBottom: contentMarginBottom
				}, bumpChartTabsAnimationDuration, "easeInOutQuart", function() {
					$push.css("margin-bottom", pushMarginBottom);
				});
			}
		}
	}

	function toggleChart() {
		if (chartAnimationComplete){
			_render();
			states.chartOpen = !states.chartOpen;
			console.log("states.chartOpen", states.chartOpen);	
		}
	}

	function toggleChartActiveTab(){
		// Search DOM
		$el.find('.active').trigger( "click" );
	}

	function getStationChartActive(){
		return stationChartActive;
	}

	function fadeIn(){
		$el.fadeTo(1000, 1);
	}

	return {
		toggleChart: toggleChart,
		stationChartTabTrigger: stationChartTabTrigger,
		operatingInstructionsTabTrigger: operatingInstructionsTabTrigger,
		bumpChartTabs: bumpChartTabs,
		getStationChartActive: getStationChartActive,
		fadeIn: fadeIn,
		toggleChartActiveTab: toggleChartActiveTab
	};

})(); //chart

export {chart};