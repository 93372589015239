import {coverlay} from './coverlay.js';
import {coverlayText} from './coverlayText.js';
import {coverlayShareButtons} from './coverlayShareButtons.js';
import {wordmark} from './wordmark.js';

// Left side tab menu
var leftSideTabMenu = (function(){

	//states
	var menuOpen = false;

	//data
	var leftSideTabMenuAnimationComplete = true;
	var leftSideTabMenuAnimationDuration = 700;
	var elWidth = 143;

	//cache DOM
	var $el = $('#left-side-tab-menu');
	var $circleHandle = $el.find('#circleHandle');
	var $circleCaret = $el.find('#circleCaret');
	var $welcomeButton = $el.find('#welcomeButton');
	var $aboutCoverlayButton = $el.find('#aboutCoverlayButton');
	var $submitFormButton = $el.find('#submitFormButton');
	var submitFormAudioElm;
	
	function init(){
		submitFormAudioElm = $('#id6')[0]; // Note: cached from outside component
	}
	
	//bind events
	$circleHandle.on('click', toggleTab);
	$welcomeButton.on('click', moveToAboutStation);
	$aboutCoverlayButton.on('click', openAboutCoverlay);
	$submitFormButton.on('click', openSubmitForm);

	function _render(){
		if (!menuOpen){
			menuOpen = true;
			console.log("menuOpen", menuOpen);
			leftSideTabMenuAnimationComplete = false;
			console.log("leftSideTabMenuAnimationComplete", leftSideTabMenuAnimationComplete);
			//Animate caret via CSS
			$circleCaret.addClass("open");
			// Animate tab
			$el.animate({
				marginLeft: '0px'
			}, leftSideTabMenuAnimationDuration, "easeInOutQuart", function() {
				leftSideTabMenuAnimationComplete = true;
				console.log("leftSideTabMenuAnimationComplete", leftSideTabMenuAnimationComplete);
			});
		} else {
			menuOpen = false;
			console.log("menuOpen", menuOpen);
			leftSideTabMenuAnimationComplete = false;
			console.log("leftSideTabMenuAnimationComplete", leftSideTabMenuAnimationComplete);
			//Animate caret via CSS
			$circleCaret.removeClass("open");
			// Animate tab
			$el.animate({
				marginLeft: -elWidth+"px"
			}, leftSideTabMenuAnimationDuration, "easeInOutQuart", function() {
				leftSideTabMenuAnimationComplete = true;
				console.log("leftSideTabMenuAnimationComplete", leftSideTabMenuAnimationComplete);
			});
		}
	}

	function toggleTab() {
		if (leftSideTabMenuAnimationComplete){
			_render();
		}
	}

	function moveToAboutStation() {
		wordmark.moveToAboutStation();
	}

	function openAboutCoverlay() {
		// Coverlay text
		coverlayText.showAboutCoverlay(true);
		// Coverlay share buttons
		coverlayShareButtons.showAboutCoverlay(true);
		// Toggle coverlay
		coverlay.toggleCoverlay();
	}
	
	function openSubmitForm() {
		// Open ajax presentation directly
		//ajaxPresentation.toggleAjaxPresentation('upload', 'uploadMessage', true);
		// Open submit form coverlay
		var audioLinkID = submitFormAudioElm.getAttribute('data-audiolinkid');
		var audioDescription = submitFormAudioElm.getAttribute('data-audiodescription');
		var coverlayTextMsg = submitFormAudioElm.getAttribute('data-coverlay');
		// Coverlay text
		coverlayText.updateUI(coverlayTextMsg);
		// Coverlay share buttons
		coverlayShareButtons.updateUI([audioLinkID, audioDescription]);
		// Toggle coverlay
		coverlay.toggleCoverlay();
	}

	return {
		init: init
	}

})(); //leftSideTabMenu

export {leftSideTabMenu};