import {states} from './states.js';
import {options} from './options.js';
import {rightSideTabMessage} from './rightSideTabMessage.js';
import {rightSideTabOmnitone} from './rightSideTabOmnitone.js';
import {audioContainer} from './audioContainer.js';
import {omnitoneHOA} from './omnitoneHOA.js';
import {marquee} from './marquee.js';
import {audioInfo} from './audioInfo.js';
import {coverlayShareButtons} from './coverlayShareButtons.js';
import {infoButton} from './infoButton.js';
import {closedCaptions} from './closedCaptions.js';
import {audioStatic} from './audioStatic.js';
import {pointer} from './pointer.js';
import {radioKnob} from './radioKnob.js';
import {radioKnobController} from './radioKnobController.js';

// Move to station
var moveToStation = (function(){

	//data
	var pointerPosFinal;
	var channel;
	var audioPlayingWithinFreqRange = false;
	var num;
	var mappedNum;

	function _render() {
		if (!states.onStation){
			marquee.updateUI("");
			audioInfo.updateUI("");
			coverlayShareButtons.updateUI();
			infoButton.updateUI();
			closedCaptions.hideCaption();
		}
	}

	function adjustVolume(){
		if (!states.onStation){
			if (states.playing){
				audioContainer.setVolume(0);
				//audioStatic.fadeIn(200);
				audioStatic.volume(options.volumeAudio);
			}
			else{
				audioContainer.setVolume(0);
			}
		} else {
			if (states.playing){
				audioContainer.setVolume(options.volumeAudio);
				// Audio static volume is being set by setVolume()
				//audioStatic.fadeOut(200);
				//audioStatic.volume(0);
			}
		}
	}

	// Mapping number ranges
	// Source: https://gist.github.com/xposedbones/75ebaef3c10060a3ee3b246166caab56
	Number.prototype.map = function (in_min, in_max, out_min, out_max) {
		return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
	};

	function leaveChannel(data){

		states.autoTuning = true;
		console.log("states.autoTuning", states.autoTuning);

		states.onStation = false;
		console.log("states.onStation", states.onStation);

		states.nearbyStation = false;
		console.log("states.nearbyStation", states.nearbyStation);

		states.onSpatialAudioStation = false;
		console.log("states.onSpatialAudioStation", states.onSpatialAudioStation);

		// Set fromStation for marquee display when seeking stations
		audioContainer.setFromStation(false);

		// If right side tab message is open, then close tab
		if (states.rightSideTabMessageOpen){
			rightSideTabMessage.toggleTab();
		}
		// If right side tab omnitone is open, then close tab
		if (states.rightSideTabOmnitoneOpen){
			rightSideTabOmnitone.toggleTab();
		}

		channel = data;
		console.log("channel", channel);

		// Get pointerPosFinal
		pointerPosFinal = radioKnobController.getPointerPosFinal();
		console.log("pointerPosFinal", pointerPosFinal);

		// Update UI
		_render();

		// Adjust volume
		adjustVolume();

		// If omnitone is playing, stop omnitone
		if (states.omnitonePlaying){
			omnitoneHOA.stopOmnitone();
		}

		// Auto rotate knob
		radioKnob.startRotate(data);

		// Set pointer position
		num = data;
		mappedNum = num.map(1, 15, 0, pointerPosFinal);
		pointer.animate(mappedNum);
		// Update pointer position value in radioKnobController
		radioKnobController.setPointerPosition(mappedNum);

		// Set channel
		radioKnobController.setChannel(channel);
	}

	function goToChannel(){
		// Note: This is called on pointer.animate() callback

		audioPlayingWithinFreqRange = false;
		//console.log("audioPlayingWithinFreqRange", audioPlayingWithinFreqRange);
		audioContainer.setAudioPlayingWithinFreqRange(audioPlayingWithinFreqRange);

		// Call channel trigger
		audioContainer.channelTrigger();

		if (states.onStation){
			// If on about station, restart audio
			/*if (channel == stations[1].freq){
				audioContainer.restartAboutStation();
			}*/
			// Adjust volume
			adjustVolume();	
		}

		// Fix bug: If pressing keyboard arrows to switch stations quickly, right side tabs remains open. Resolved by setting timeout.
		setTimeout(function(){
			states.autoTuning = false;
			console.log("states.autoTuning", states.autoTuning);
		}, 200);
	}

	function start(data) {
		if (!states.autoTuning && !states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen){
			leaveChannel(data);
		}
	}

	function startPhoneHeld(data) {
		if (!states.autoTuning && !states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen){

			// Get pointerPosFinal
			pointerPosFinal = radioKnobController.getPointerPosFinal();
			console.log("pointerPosFinal", pointerPosFinal);

			// Set pointer position
			num = data;
			mappedNum = num.map(1, 15, 0, pointerPosFinal);
			pointer.updateUI(mappedNum);
			// Update pointer position value in radioKnobController
			radioKnobController.setPointerPosition(mappedNum);

			// Set channel
			radioKnobController.setChannel(data);

			// Go to channel
			goToChannel();
		}
	}

	return {
		start: start,
		startPhoneHeld: startPhoneHeld,
		goToChannel: goToChannel
	};

})(); //moveToStation

export {moveToStation};