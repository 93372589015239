// Libraries
// Note: linked in HTML markup for page loader
//const bowser = require('bowser');

// States
import {states} from './states.js';
// Options
import {options} from './options.js';
// Modules
import {socketListen} from './socketListen.js';
import {enterPage} from './enterPage.js';
import {coverlay} from './coverlay.js';
import {closedCaptionsIcon} from './closedCaptionsIcon.js';
import {spotlightIcon} from './spotlightIcon.js';
import {coverlayShareButtons} from './coverlayShareButtons.js';
import {infoButton} from './infoButton.js';
import {operatingInstructions} from './operatingInstructions.js';
import {playButton} from './playButton.js';
import {radioKnob} from './radioKnob.js';
import {remoteStatus} from './remoteStatus.js';
import {rightSideTabMessage} from './rightSideTabMessage.js';
import {rightSideTabOmnitone} from './rightSideTabOmnitone.js';
import {audioStatic} from './audioStatic.js';
import {hashtagValue} from './hashtagValue.js';
import {browserErrorMessage} from './browserErrorMessage.js';
import {passwordProtectForm} from './passwordProtectForm.js';
import {ajaxPresentation} from './ajaxPresentation.js';
import {keyboardCommands} from './keyboardCommands.js';

// Init
enterPage.init();
coverlay.init();
closedCaptionsIcon.init();
spotlightIcon.init();
coverlayShareButtons.init();
infoButton.init();
operatingInstructions.init();
playButton.init();
radioKnob.init();
remoteStatus.init();
rightSideTabMessage.init();
rightSideTabOmnitone.init();
audioStatic.init();
hashtagValue.init(); // Call last

// Loader
Pace.on('hide', function(){
	console.log('done');
	if (bowser.msie){
		console.log("Internet Explorer detected");
		browserErrorMessage.fadeIn();
	} else{
		if (options.passwordProtect){
			passwordProtectForm.init();
		} else {
			enterPage.fadeIn();
		}
	}
});

// Window object used to send data from database coverlay enter buttons, ajax presentations, and desktop script
// Call from ajax presentations
window.ajaxPresentationGetAjaxHasScrollbar = function(){
	return ajaxPresentation.getAjaxHasScrollbar();
}
window.coverlayGetScrollbarWidth = function(){
	return coverlay.getScrollbarWidth();
}
window.getSocketRoomCode = function(){
	return states.socketRoomCode;
}
window.ajaxPresentationGetCurrentAjaxModule = function(){
	return ajaxPresentation.getCurrentAjaxModule();
}
// Call from database coverlay enter buttons and ajax presentations
window.ajaxPresentationToggleAjaxPresentation = function(id, template, boolean){
	ajaxPresentation.toggleAjaxPresentation(id, template, boolean);
}