// Browser error message
var browserErrorMessage = (function(){

	//data
	var loaded = false;

	//cache DOM
	var $el = $('#browser-error-message');

	function fadeIn() {
		if (!loaded){
			loaded = true;
			console.log("loaded", loaded);
			$el.show();
			$el.fadeTo(1000, 1);
		}
	}

	return {
		fadeIn: fadeIn
	};

})(); //browserErrorMessage

export {browserErrorMessage};