import {stations} from '../database.js';
import {radioKnobController} from './radioKnobController.js';
import {moveToStation} from './moveToStation.js';

// Seek stations
var seekStations = (function(){

	//data
	var channel;
	var stationFrequencies = [];
	var stationFrequenciesNoGhostStations = [];
	var arrSmallerElements;
	var arrBiggerElements;
	var prevElement;
	var nextElement;

	// Push freq values from stations array to stationFrequencies array
	//Source: https://stackoverflow.com/questions/19590865/from-an-array-of-objects-extract-value-of-a-property-as-array
	stationFrequencies = stations.map(a => a.freq);
	console.log("stationFrequencies", stationFrequencies);

	// Remove ghost stations
	stationFrequenciesNoGhostStations = stationFrequencies.slice(-0, -2);
	console.log("stationFrequenciesNoGhostStations", stationFrequenciesNoGhostStations);

	// Move to previous or next item in frequency array
	// Source: https://stackoverflow.com/questions/8584902/get-closest-number-out-of-array
	function BiggerThan(inArray) {
		return inArray > channel;
	}
	function SmallerThan(inArray) {
		return inArray < channel;
	}

	function prevStation(){
		// Move to previous item in frequency array
		channel = radioKnobController.getChannel();
		if (channel > stationFrequenciesNoGhostStations[0]){
			arrSmallerElements = stationFrequenciesNoGhostStations.filter(SmallerThan);
			prevElement = Math.max.apply(null, arrSmallerElements);
			console.log("channel", channel);
			console.log("prevElement", prevElement);
			moveToStation.start(prevElement);
		}
	}

	function nextStation(){
		// Move to next item in frequency array
		channel = radioKnobController.getChannel();
		if (channel < stationFrequenciesNoGhostStations[stationFrequenciesNoGhostStations.length-1]){
			arrBiggerElements = stationFrequenciesNoGhostStations.filter(BiggerThan);
			nextElement = Math.min.apply(null, arrBiggerElements);
			console.log("channel", channel);
			console.log("nextElement", nextElement);
			moveToStation.start(nextElement);
		}
	}

	return {
		prevStation: prevStation,
		nextStation: nextStation
	};

})(); //seekStations

export {seekStations};