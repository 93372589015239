import {states} from './states.js';
import {options} from './options.js';
import {coverlay} from './coverlay.js';
import {coverlayText} from './coverlayText.js';

// Right side tab message
var rightSideTabMessage = (function(){

	//data
	var rightSideTabAnimationComplete = true;
	var rightSideTabAnimationDuration = 700;
	var elWidth;

	//cache DOM
	var $el = $('#right-side-tab-message');

	//bind events
	$el.on('click', toggleCoverlay);

	function init(){
		// Get element width
		elWidth = $el.width();

		var hasAjaxPresentation = coverlayText.detectAjaxPresentation();
		console.log("hasAjaxPresentation", hasAjaxPresentation);

		if (states.onStation && hasAjaxPresentation && !options.disableRightSideTabMessage){
			states.rightSideTabMessageOpen = true;
			console.log("states.rightSideTabMessageOpen", states.rightSideTabMessageOpen);
		} else {
			states.rightSideTabMessageOpen = false;
			console.log("states.rightSideTabMessageOpen", states.rightSideTabMessageOpen);
			// Hide element
			$el.css("margin-right", -elWidth-3+"px");
		}
	}

	function _render(){
		if (!states.rightSideTabMessageOpen){
			states.rightSideTabMessageOpen = true;
			console.log("states.rightSideTabMessageOpen", states.rightSideTabMessageOpen);
			rightSideTabAnimationComplete = false;
			console.log("rightSideTabAnimationComplete", rightSideTabAnimationComplete);
			// Animate tab
			$el.animate({
				marginRight: '0px'
			}, rightSideTabAnimationDuration, "easeInOutQuart", function() {
				rightSideTabAnimationComplete = true;
				console.log("rightSideTabAnimationComplete", rightSideTabAnimationComplete);
			});
		} else {
			states.rightSideTabMessageOpen = false;
			console.log("states.rightSideTabMessageOpen", states.rightSideTabMessageOpen);
			// Animate tab
			$el.animate({
				marginRight: -elWidth-3+"px"
			}, rightSideTabAnimationDuration, "easeInOutQuart", function() {
				rightSideTabAnimationComplete = true;
				console.log("rightSideTabAnimationComplete", rightSideTabAnimationComplete);
			});
		}
	}

	function toggleTab() {
		if (!options.disableRightSideTabMessage){
			_render();
		}
	}

	function toggleCoverlay() {
		coverlay.toggleCoverlay();
	}

	return {
		init: init,
		toggleTab: toggleTab
	};

})(); //rightSideTabMessage

export {rightSideTabMessage};