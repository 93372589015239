// Overlay prevent click
var overlayPreventClick = (function(){

	//cache DOM
	var $el = $('#overlayPreventClick');

	function hideOverlay(){
		$el.hide();
	}

	return {
		hideOverlay: hideOverlay
	};

})(); //overlayPreventClick

export {overlayPreventClick};