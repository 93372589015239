import {rootURL} from '../config.js';
import {stations} from '../database.js';
import {states} from './states.js';
import {options} from './options.js';

// Coverlay share buttons
var coverlayShareButtons = (function(){

	//states
	var aboutCoverlayOpen = false;

	//data
	var audioLinkID;
	var audioDescription;
	var aboutShortwaveDescription = "CDS Shortwave, Transmitting from the Center for Documentary Studies at Duke University";
	var spatialAudioStation = stations[4].freq;

	//cache DOM
	var $el = $('#coverlay-share-buttons');

	function init(){
		if (options.disableShareButtons){
			disable();
		}
		if (options.hideShareButtons){
			$el.hide();
		}
	}

	function _render() {
		if (aboutCoverlayOpen){
			initJsSocials(rootURL, aboutShortwaveDescription);
		} else if (states.onSpatialAudioStation) {
			initJsSocials(rootURL+"#"+spatialAudioStation, audioDescription);
		} else {
			initJsSocials(rootURL+"spotlight?"+audioLinkID, audioDescription);
		}
	}

	function initJsSocials(urlData, textData) {
		// jsSocials
		$el.jsSocials({
			url: urlData,
			text: textData+":",
			shareIn: "popup",
			showLabel: false,
			showCount: false,
			shares: ["email", "twitter", "facebook"]
		});
	}

	function destroyJsSocials(){
		$el.jsSocials("destroy");
	}

	function enable(){
		$el.removeClass("avoid-clicks");
	}

	function disable(){
		$el.addClass("avoid-clicks");
	}

	function showAboutCoverlay(boolean) {
		aboutCoverlayOpen = boolean;
		console.log("aboutCoverlayOpen", aboutCoverlayOpen);
		_render();
	}

	function updateUI(data) {
		if (typeof data  !== "undefined") {
			audioLinkID = data[0];
			audioDescription = data[1];
			_render();
		} else {
			destroyJsSocials();
		}
	}

	return {
		init: init,
		updateUI: updateUI,
		showAboutCoverlay: showAboutCoverlay
	};

})(); //coverlayShareButtons

export {coverlayShareButtons};