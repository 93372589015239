import {states} from './states.js';
import {closedCaptions} from './closedCaptions.js';

// Closed captions icon
var closedCaptionsIcon = (function(){

	//cache DOM
	var $el = $('#closedCaptionsIcon');
	var $image = $el.find('img');

	//bind events
	$el.on('click', toggleCaptions);

	function init(){
		_render();
	}

	function _render() {
		if (states.captionsOn){
			$image.attr("src","images/icons/cc-on.svg");
		} else {
			$image.attr("src","images/icons/cc-off.svg");
		}
	}

	function disableAnimation(){
		$el.addClass('notransition');
	}

	function enableAnimation(){
		$el.removeClass('notransition');
	}

	function moveIcon(data){
		$el.css({"margin-right": data+"px"});
	}

	function toggleCaptions(){
		closedCaptions.toggleCaptions();
	}

	function updateUI() {
		_render();
	}

	return {
		init: init,
		updateUI: updateUI,
		disableAnimation: disableAnimation,
		enableAnimation: enableAnimation,
		moveIcon: moveIcon
	};

})(); //closedCaptionsIcon

export {closedCaptionsIcon};