import {states} from './states.js';
import {options} from './options.js';
import {radioKnob} from './radioKnob.js';
import {pointer} from './pointer.js';
import {mouseScroll} from './mouseScroll.js';
import {phoneRotate} from './phoneRotate.js';
import {audioContainer} from './audioContainer.js';

// Radio knob controller
var radioKnobController = (function(){
	
	//data
	var dragging = false,
		anguloAtual = 0,
		anguloInicial = null,
		knobCenterX = 0,
		knobCenterY = 0,
		displayWidth = 504,
		channel = 1.0,
		pointerPosition = 0,
		pointerPosFinal = 392,
		anguloEmRadianos,
		anguloRelativo,
		angTemp,
		anguloTemp,
		pointerSpeedLimit;


	//cache DOM
	var $el = $('#radio-knob-controller');
	var $controller = $el.find('#controller');
	var $controllerCont = $el.find('#controllerCont');
	var	knobOffset = $controllerCont.offset(),
			knobWidth = $controllerCont.width(),
			knobHeight = $controllerCont.height(),
			knobCenterX = knobOffset.left + knobWidth / 2,
			knobCenterY = knobOffset.top + knobHeight / 2;

	//bind events
	$el.on('mousedown', startDrag);
	$el.on('mousemove', doDrag);
	window.addEventListener('mouseup', stopDrag);
	window.addEventListener('resize', findCenter);

	function _render() {
		$controller.css("transform", "rotate(" + anguloAtual + "deg)");
		radioKnob.updateUI(anguloAtual);
		pointer.updateUI(pointerPosition);
	}

	function findCenter(){
		knobOffset = $controllerCont.offset();
		knobWidth = $controllerCont.width();
		knobHeight = $controllerCont.height();
		knobCenterX = knobOffset.left + knobWidth / 2;
		knobCenterY = knobOffset.top + knobHeight / 2;
	}

	function startDrag(event) {
		dragging = true;
		anguloEmRadianos = Math.atan2(knobCenterY - event.clientY, knobCenterX - event.clientX);
		anguloInicial = (anguloEmRadianos * (180 / Math.PI)) - anguloAtual;
	}

	function stopDrag() {
		dragging = false;
		anguloInicial = null;
	}

	function doDrag(event) {
		if (dragging && anguloInicial !== null && !states.autoTuning) {

			anguloEmRadianos = Math.atan2(knobCenterY - event.clientY, knobCenterX - event.clientX);
			anguloRelativo = anguloEmRadianos * (180 / Math.PI);
			anguloTemp = anguloAtual;

			anguloAtual = (anguloInicial * 0) + (anguloRelativo - anguloInicial);
			angTemp = (anguloTemp - anguloAtual);

			if (angTemp > 180) {
				angTemp -= 360;
			}
			if (angTemp < -180) {
				angTemp += 360;
			}

			// Limit pointer speed
			pointerSpeedLimit = angTemp * -0.14;

			// Sync anguloAtual local variables
			mouseScroll.setAnguloAtual(anguloAtual);
			phoneRotate.setAnguloAtual(anguloAtual);

			// Call switchChannel()
			switchChannel(pointerSpeedLimit);
		}
	}

	function switchChannel(pointerSpeedLimit){
		if (!states.stationStuck){
			if (pointerSpeedLimit < -2) {
				pointerSpeedLimit = -2;
			}
			if (pointerSpeedLimit > 2) {
				pointerSpeedLimit = 2;
			}

			pointerPosition += pointerSpeedLimit;

			if (pointerPosition < 0) {
				pointerPosition = 0;
			} else if (pointerPosition > pointerPosFinal) {
				pointerPosition = pointerPosFinal;
			}

			// Calculate channel
			var newChannel = (1 + 2*(pointerPosition * 9)/ displayWidth).toFixed(1);

			if (newChannel != channel) {
				channel = newChannel;
				audioContainer.setVolume(options.volumeAudio);
			}

			console.log("channel", channel);

			audioContainer.channelTrigger();
		}

		// Update UI
		_render();
	}

	function getChannel() {
		return channel;
	}

	function setChannel(data) {
		channel = data;
	}

	function getPointerPosFinal() {
		return pointerPosFinal;
	}

	function setPointerPosition(data) {
		pointerPosition = data;
	}

	function setAnguloAtual(data) {
		anguloAtual = data;
	}

	return {
		getChannel: getChannel,
		setChannel: setChannel,
		getPointerPosFinal: getPointerPosFinal,
		setPointerPosition: setPointerPosition,
		setAnguloAtual: setAnguloAtual,
		switchChannel: switchChannel,
		findCenter: findCenter
	};

})(); //radioKnobController

export {radioKnobController};