import {S3_PATH} from '../config.js';
import {stations} from '../database.js';
import {playlists} from '../playlists.js';
import {states} from './states.js';
import {options} from './options.js';
import {socketEmit} from './socketEmit.js';
import {closedCaptions} from './closedCaptions.js';
import {radioKnobController} from './radioKnobController.js';
import {marquee} from './marquee.js';
import {audioStatic} from './audioStatic.js';
import {hashtagValue} from './hashtagValue.js';
import {audioInfo} from './audioInfo.js';
import {coverlayText} from './coverlayText.js';
import {coverlayShareButtons} from './coverlayShareButtons.js';
import {infoButton} from './infoButton.js';
import {rightSideTabMessage} from './rightSideTabMessage.js';
import {rightSideTabOmnitone} from './rightSideTabOmnitone.js';
import {omnitoneHOA} from './omnitoneHOA.js';
import {stationChart} from './stationChart.js';
import {leftSideTabMenu} from './leftSideTabMenu.js';

// Audio container
var audioContainer = (function(){

	//states
	var freqWithinGhostChannelRange = false;
	var audioPlayingWithinFreqRange = false;
	var fromGhostStation = false;
	var fromStation = false;
	var fromPlaylistAdvance = false;
	var initialTracksLoaded = false;

	// Options
	var pauseAudioWhenOutOfFreqRange = false;
	var stickyStationsPauseDuration = 500;

	//data
	var audios = {};
	var volumes = {};
	var channel;
	var loadedTrack = 0;
	var currentAudio;
	var currentStationID;
	var minutesDuration;
	var secondsDuration;
	var hasAjaxPresentation;
	var randomPosition;
	var timerRightSideTabMessage;
	var timerRightSideTabOmnitone;
	var audioElements = [];
	var current = [];

	//cache DOM
	var $el = $('#audio-container');

	function init(){
		initAudioElementsAndArrays();
	}

	function initAudioElementsAndArrays() {
		for (var i=0; i < stations.length; i++) {
			var station = stations[i]
			// Create audio elements
			var newAudio = document.createElement('audio');
			//var newAudio = document.createElement('video');
			// Uncomment below to visualize audio volume fading
			//newAudio.setAttribute('controls', 'true');
			newAudio.setAttribute('preload', 'metadata');
			newAudio.setAttribute("id", station.id);
			$el.append(newAudio);
			newAudio.volume = 0;
			// Build audios and volumes arrays for audio volume mixing
			audios[station.freq] = newAudio;
			for (var j = -0.5; j <= 0.5; j+=0.1){
				audios[(station.freq + j).toFixed(1)] = newAudio;
				volumes[(station.freq + j).toFixed(1)] = 1*(1-Math.abs(j+j)).toFixed(1);
			}
			// Build audio elements array
			audioElements.push(newAudio);
			// Build current array, initial position is 0 to start at beginning of playlist
			current.push(0);
		}

		//console.log(audios);
		//console.log(volumes);
		//console.log(audioElements);
		//console.log(current);

		shufflePlaylists();
	}

	function shuffle(array) {
		var currentIndex = array.length, temporaryValue, randomIndex;

		// While there remain elements to shuffle
		while (0 !== currentIndex) {
			// Pick a remaining element
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			// And swap it with the current element
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}

		console.log(array);
		return array;
	}

	function shufflePlaylists(){
		for (var i=0; i < playlists.length; i++) {
			shuffle(playlists[i]);
		}
		initAudioContent();
	}

	function initAudioContent() {
		for (let i=0; i < audioElements.length; i++) {

			loadAudio(audioElements[i], playlists[i], current[i], false);

			// If ghost station, loop audio
			if (i == 8 || i == 9){
				audioElements[i].loop = true;
			} else {
				// Advance playlist on end
				audioElements[i].onended = function() {
					current[i]++;
					if (current[i] == playlists[i].length) {
						current[i] = 0;
					}
					advanceAudio(audioElements[i], playlists[i], current[i], true, i);
				};
			}
		}
	}

	function loadAudio(player, playlist, index, loadFile) {

		var audiolinkid = playlist[index].audiolinkid;
		var srclink = playlist[index].srclink;
		var description = playlist[index].description;
		var coverlayHeading = playlist[index].coverlayHeading;
		var coverlaySubheading = playlist[index].coverlaySubheading;
		var coverlayParagraph = playlist[index].coverlayParagraph;
		var ajax = playlist[index].ajax;
		var ajaxTemplate = playlist[index].ajaxTemplate;
		var ajaxHasScrollBar = playlist[index].ajaxHasScrollBar;

		player.innerHTML = '<source src="' + S3_PATH + srclink+'.mp3' + '" type="audio/mpeg"><track label="English subtitles" kind="subtitles" srclang="en" src="captions/'+audiolinkid+'.vtt" default>';
		player.setAttribute("data-audiolinkid", audiolinkid);
		player.setAttribute("data-audiodescription", description);

		// Generate coverlay text
		var coverlayTextBuild = "<h1>"+coverlayHeading+"</h1><h2>"+coverlaySubheading+"</h2><p>"+coverlayParagraph+"</p>";

		// If audio has ajax presentation
		if (ajax){
			// If on upload station, force ajax presentation ID
			if (player === getUploadAudioElm()){
				var ajaxPresentationID = "upload";
			} else {
				// If not on upload station, define ajax presentation ID as audiolinkid
				var ajaxPresentationID = audiolinkid;
			}
			// Generate ajax button
			var ajaxButtonBuild = '<div class="wrapEnterButton"><a class="requestAjaxOverlay" onclick="ajaxPresentationToggleAjaxPresentation(\''+ajaxPresentationID+'\','+'\''+ajaxTemplate+'\','+ajaxHasScrollBar+')" rel="nofollow"><div class="enterButton">Enter</div></a></div>';
			//console.log("ajaxButtonBuild", ajaxButtonBuild);
			// Combine coverlay text with ajax button
			var coverlayBuild = coverlayTextBuild.concat(ajaxButtonBuild);
		} else {
			// If audio does not have ajax presentation, define coverlay as coverlay text only
			var coverlayBuild = coverlayTextBuild;
		}

		// Set data-coverlay attribute
		player.setAttribute("data-coverlay", coverlayBuild);

		// Load audio if loadFile is true
		// Uncomment line below to also load audio if Safari browser is detected to prevent playback lag
		//if (loadFile || bowser.safari){
		if (loadFile){
			player.load();
		}

		// Initialize closed captions after all initial tracks are loaded
		if (!initialTracksLoaded){
			loadedTrack++;
			if(loadedTrack == stations.length){
				initialTracksLoaded = true;
				console.log("initialTracksLoaded", initialTracksLoaded);
				closedCaptions.init();
				stationChart.init();
				leftSideTabMenu.init();
				// Set initial value of currentAudio
				currentAudio = audioElements[0];
			}
		}
	}

	function advanceAudio(player, playlist, index, loadFile, i) {

		loadAudio(
			player,
			playlist,
			index,
			loadFile
		);

		// If ghost station, exit
		if (i == 8 || i == 9){
			return;
		}

		// Load captions
		closedCaptions.loadCaptions();

		// Update station chart
		stationChart.updateUI(i, playlist[index].description);
				
		// Nearby or on station events
		if (channel >= stations[i].freq-0.5 && channel <= stations[i].freq+0.5){
			// Add listener
			player.addEventListener("canplay", initPlay(player));
			nearbyStationEvents(player, i, true);
			if (channel == stations[i].freq){
				onStationEvents(player, i, true);
			}
		}
	}

	function setVolume(vol){

		channel = radioKnobController.getChannel();

		if (states.playing) {
			if (channel in volumes) {
				audioStatic.volume((1 - volumes[channel]) * vol);
				audios[channel].volume = volumes[channel] * vol;
			} else {
				audioStatic.volume(vol);
			}
		} else {
			audioStatic.volume(0);
			if (channel in audios) {
				audios[channel].volume = 0;
			}
		}
	};

	// Source: http://qnimate.com/get-html5-audio-duration-length/
	// Source: https://www.w3schools.com/jsref/event_ontimeupdate.asp
	function getAudioPosition(audio, stationID, playlistAdvance) {

		// Have to define these variables outside function for onTimeUpdate and notNearbyStationEvents, so that timeupdate eventListener can be added and removed
		currentAudio = audio;
		currentStationID = stationID;

		var hashtagValueNearbyStation = hashtagValue.getHashtagValueNearbyStation();
		console.log("hashtagValueNearbyStation", hashtagValueNearbyStation);

		if (playlistAdvance || hashtagValueNearbyStation) {
			hashtagValueNearbyStation = false;
			console.log("hashtagValueNearbyStation", hashtagValueNearbyStation);
			hashtagValue.setHashtagValueNearbyStation(hashtagValueNearbyStation);
			audio.addEventListener("canplaythrough", updateDisplay);
		}
		else {
			updateDisplay();
		}
		
		function updateDisplay() {
			
			if (playlistAdvance) {
				console.log("Audio can play through without stopping");
			}

			if(audio.readyState > 0) {
				minutesDuration = parseInt(audio.duration / 60, 10);
				secondsDuration = parseInt(audio.duration % 60);
				if (secondsDuration.toString().length == 1){
					secondsDuration = "0"+secondsDuration;
				}

				console.log("Audio duration: ", minutesDuration+":"+secondsDuration);

				var minutesCurrent = parseInt(audio.currentTime / 60, 10);
				var secondsCurrent = parseInt(audio.currentTime % 60);
				if (secondsCurrent.toString().length == 1){
					secondsCurrent = "0"+secondsCurrent;
				}

				console.log("Audio position: ", minutesCurrent+":"+secondsCurrent);

				audioInfo.updateUI(stations[stationID].freq+'   '+stations[stationID].stationinfo+' ('+minutesCurrent+':'+secondsCurrent+'/' +minutesDuration+':'+secondsDuration+')');
			}

			audio.addEventListener('timeupdate', onTimeUpdate);

			if (playlistAdvance) {
				audio.removeEventListener("canplaythrough", updateDisplay);
				console.log("Listener removed");
			}
		}
	}

	function onTimeUpdate(){
		console.log("onTimeUpdate called");
		if (channel >= stations[currentStationID].freq-0.5 && channel <= stations[currentStationID].freq+0.5){
			var minutesCurrent = parseInt(currentAudio.currentTime / 60, 10);
			var secondsCurrent = parseInt(currentAudio.currentTime % 60);
			if (secondsCurrent.toString().length == 1){
				secondsCurrent = "0"+secondsCurrent;
			}

			console.log("Audio position: ", minutesCurrent+":"+secondsCurrent);

			audioInfo.updateUI(stations[currentStationID].freq+'   '+stations[currentStationID].stationinfo+' ('+minutesCurrent+':'+secondsCurrent+'/' +minutesDuration+':'+secondsDuration+')');
		}
	}

	function nearbyStationEvents(audio, stationID, playlistAdvance) {
		states.nearbyStation = true;
		console.log("states.nearbyStation", states.nearbyStation);
		fromPlaylistAdvance = playlistAdvance;
		console.log("fromPlaylistAdvance", fromPlaylistAdvance);
		var audioDescription = audio.getAttribute('data-audiodescription');
		// Marquee message
		marquee.updateUI(audioDescription);
		// Audio info
		getAudioPosition(audio, stationID, playlistAdvance);
		// Load omnitone if nearby spatial audio channel
		/*if (audio.id === "id4"){
			omnitoneHOA.init();
		}*/
	}

	function notNearbyStationEvents() {
		states.nearbyStation = false;
		console.log("states.nearbyStation", states.nearbyStation);
		fromStation = false;
		console.log("fromStation", fromStation);
		// Marquee message
		marquee.updateUI("");
		// Audio info
		audioInfo.updateUI("");
		// Remove current audio event listner
		currentAudio.removeEventListener('timeupdate', onTimeUpdate);
	}

	function onStationEvents(audio, stationID, playlistAdvance) {
		if (options.stickyStations){
			states.stationStuck = true;
			console.log("states.stationStuck", states.stationStuck);
			setTimeout(function(){
				states.stationStuck = false;
				console.log("states.stationStuck", states.stationStuck);
			}, stickyStationsPauseDuration);
		}
		states.onStation = true;
		console.log("states.onStation", states.onStation);
		fromStation = true;
		console.log("fromStation", fromStation);
		fromGhostStation = false;
		console.log("fromGhostStation", fromGhostStation);
		var audioLinkID = audio.getAttribute('data-audiolinkid');
		var audioDescription = audio.getAttribute('data-audiodescription');
		var coverlayTextMsg = audio.getAttribute('data-coverlay');
		// Marquee message
		marquee.updateUI(audioDescription);
		// Audio info
		getAudioPosition(audio, stationID, playlistAdvance);
		// Coverlay text
		coverlayText.updateUI(coverlayTextMsg);
		// Coverlay share buttons
		coverlayShareButtons.updateUI([audioLinkID, audioDescription]);
		// Info button UI
		infoButton.updateUI();
		// Closed captions
		closedCaptions.showCaption(parseInt(stationID));
		// Send onStation state to remote
		socketEmit.state("onStation", true);
		// Send hasAjaxPresentation boolean to remote
		hasAjaxPresentation = coverlayText.detectAjaxPresentation();
		socketEmit.send("returnHasAjaxPresentation", hasAjaxPresentation);
		// If audio has ajax presentation and right side tab message is closed, then open tab
		if (hasAjaxPresentation && !states.rightSideTabMessageOpen){
			// Set timer for rightSideTabMessage
			timerRightSideTabMessage = setTimeout(function(){
				rightSideTabMessage.toggleTab();
			}, 200);
		} else if (!hasAjaxPresentation && states.rightSideTabMessageOpen){
			// Set timer for rightSideTabMessage
			timerRightSideTabMessage = setTimeout(function(){
				rightSideTabMessage.toggleTab();
			}, 200);
		}
	};

	function onGhostStationEvents(stationID) {
		states.onStation = true;
		console.log("states.onStation", states.onStation);
		states.onGhostStation = true;
		console.log("states.onGhostStation", states.onGhostStation);
		fromGhostStation = true;
		console.log("fromGhostStation", fromGhostStation);
		// Marquee message
		marquee.updateUI("");
		// Closed captions
		//closedCaptions.showCaption(parseInt(stationID));
	};

	function onSpatialAudioStationEvents() {
		states.onSpatialAudioStation = true;
		console.log("states.onSpatialAudioStation", states.onSpatialAudioStation);
		// If right side tab omnitone is closed, then open tab
		if (!states.rightSideTabOmnitoneOpen){
			// Set timer for rightSideTabOmnitone
			timerRightSideTabOmnitone = setTimeout(function(){
				rightSideTabOmnitone.toggleTab();
			}, 200);
		}
		// If radio is playing and omnitone is not playing, play omnitone and null track for audio info display
		if (states.playing && !states.omnitonePlaying){
			omnitoneHOA.playOmnitone();
			playSpatialAudio();
		}
	};

	function offStationEvents(){
		states.onStation = false;
		console.log("states.onStation", states.onStation);
		states.onGhostStation = false;
		console.log("states.onGhostStation", states.onGhostStation);
		states.onSpatialAudioStation = false;
		console.log("states.onSpatialAudioStation", states.onSpatialAudioStation);
		marquee.updateUI("");
		audioInfo.updateUI("");
		coverlayShareButtons.updateUI();
		infoButton.updateUI();
		closedCaptions.hideCaption();
		if (!fromGhostStation){
			// Sent onStation state to remote
			socketEmit.state("onStation", false);
		}
		// If right side tab message is open, then close tab
		if (states.rightSideTabMessageOpen){
			rightSideTabMessage.toggleTab();
		}
		// If right side tab omnitone is open, then close tab
		if (states.rightSideTabOmnitoneOpen){
			rightSideTabOmnitone.toggleTab();
		}
		// If playing omnitone, stop omnitone and null track for audio info display
		if (states.omnitonePlaying){
			omnitoneHOA.stopOmnitone();
			pauseSpatialAudio();
			restartSpatialAudio();
		}
		// Clear timer for rightSideTabMessage
		clearTimeout(timerRightSideTabMessage);
		// Clear timer for rightSideTabOmnitone
		clearTimeout(timerRightSideTabOmnitone);
	};

	function channelTrigger(){

		channel = radioKnobController.getChannel();
		console.log("channel", channel);

		// Play or pause audio when approaching, leaving, or hitting channel frequency
		for (var i=0; i < stations.length; i++) {

			//if (channel == Math.round((stations[i].freq-0.5) * 100) / 100 || channel == Math.round((stations[i].freq+0.5) * 100) / 100 || channel == Math.round((stations[i].freq) * 100) / 100){

			if (channel >= Math.round((stations[i].freq-0.5) * 100) / 100 && channel <= Math.round((stations[i].freq+0.5) * 100) / 100){

				if (!audioPlayingWithinFreqRange){
					audioPlayingWithinFreqRange = true;
					console.log("audioPlayingWithinFreqRange", audioPlayingWithinFreqRange);
					// If near or on any station except ghost stations, get station and call nearbyStationEvents()
					if ( !(i == 8 || i == 9) ){
						var nearbyAudio = audioElements[i];
						nearbyStationEvents(nearbyAudio, i, false);
					}
					// When within frequency range of ghost channels and enter page is not open, randomize position and play
					if ((i == 8 || i == 9) && !freqWithinGhostChannelRange && !states.enterPageOpen){
						freqWithinGhostChannelRange = true;
						console.log("freqWithinGhostChannelRange", freqWithinGhostChannelRange);
						randomPosition = Math.floor(Math.random() * audioElements[i].duration);
						audioElements[i].currentTime = randomPosition;
						console.log("randomPosition", randomPosition);
						if (states.playing){
							// Add listener
							audioElements[i].addEventListener("canplay", initPlay(audioElements[i]));
						}
					}
					else{
						// If playing and channel is not approaching spatial audio station, then play
						if (states.playing && i != 4){
							// Add listener
							audioElements[i].addEventListener("canplay", initPlay(audioElements[i]));
						}
					}
				}
			}
			else if (channel == Math.round((stations[i].freq-0.6) * 100) / 100 || channel == Math.round((stations[i].freq+0.6) * 100) / 100){

				if (audioPlayingWithinFreqRange){
					audioPlayingWithinFreqRange = false;
					console.log("audioPlayingWithinFreqRange", audioPlayingWithinFreqRange);
					// If not near or not on any station except ghost stations, call notNearbyStationEvents()
					if ( !(i == 8 || i == 9) ){
						notNearbyStationEvents();
					}
					// When outside frequency range of ghost channels, pause and advance playlist if not Safari browser
					if ((i == 8 || i == 9) && freqWithinGhostChannelRange){
						freqWithinGhostChannelRange = false;
						console.log("freqWithinGhostChannelRange", freqWithinGhostChannelRange);
						audioElements[i].pause();
						if ( (i == 8 || i == 9) && !bowser.safari){
							current[i]++;
							if (current[i] == playlists[i].length) {
								current[i] = 0;
							}
							advanceAudio(audioElements[i], playlists[i], current[i], true, i);
						}
					}
					// Pause audio when out of frequency range
					if (pauseAudioWhenOutOfFreqRange){
						audioElements[i].pause();
					}
				}
			}
		}

		// If channel is on a station except ghost stations
		/*for (var i=0; i < stations.length-2; i++) {
			if (channel == Math.round((stations[i].freq) * 100) / 100){
			}
		}*/

		// Specific channel events
		if (channel == stations[0].freq){
			if (!states.onStation){
				onStationEvents(audioElements[0], "0", false);
			}
		}
		// About channel
		else if (channel == stations[1].freq){
			if (!states.onStation){
				onStationEvents(audioElements[1], "1", false);
			}
		}
		else if (channel == stations[2].freq){
			if (!states.onStation){
				onStationEvents(audioElements[2], "2", false);
			}
		}
		else if (channel == stations[3].freq){
			if (!states.onStation){
				onStationEvents(audioElements[3], "3", false);
			}
		}
		// Omnitone
		else if (channel == stations[4].freq){
			if (!states.onStation){
				restartSpatialAudio();
				onSpatialAudioStationEvents();
				onStationEvents(audioElements[4], "4", false);
			}
		}
		else if (channel == stations[5].freq){
			if (!states.onStation){
				onStationEvents(audioElements[5], "5", false);
			}
		}
		// Upload
		else if (channel == stations[6].freq){
			if (!states.onStation){
				onStationEvents(audioElements[6], "6", false);
			}
		}
		else if (channel == stations[7].freq){
			if (!states.onStation){
				onStationEvents(audioElements[7], "7", false);
			}
		}
		// For ghost channel with no info display
		else if (channel == stations[8].freq){
			if (!states.onStation){
				onGhostStationEvents("8");
			}
		}
		// For ghost channel with no info display
		else if (channel == stations[9].freq){
			if (!states.onStation){
				onGhostStationEvents("9");
			}
		}
		else {
			if (states.onStation){
				offStationEvents();
			}
		}
	};

	function initPlay(elm){
		//console.log("elm", elm);
		// Remove listener
		elm.removeEventListener("canplay", initPlay);
		elm.play();
	}

	function fadeIn() {
		$(audios[channel]).animate({volume: options.volumeAudio}, 1000);
	}

	function fadeOut() {
		$(audios[channel]).animate({volume: 0}, 1000);
	}

	function getAudioPlayingWithinFreqRange() {
		return audioPlayingWithinFreqRange;
	}

	function setAudioPlayingWithinFreqRange(data) {
		audioPlayingWithinFreqRange = data;
		console.log("audioPlayingWithinFreqRange", audioPlayingWithinFreqRange);
	}

	function getFromStation() {
		return fromStation;
	}

	function setFromStation(data){
		fromStation = data;
		console.log("fromStation", fromStation);
	}

	function getFromPlaylistAdvance() {
		return fromPlaylistAdvance;
	}

	function setFromPlaylistAdvance(data){
		fromPlaylistAdvance = data;
		console.log("fromPlaylistAdvance", fromPlaylistAdvance);
	}

	function restartAboutStation(){
		audioElements[1].currentTime = 0;
	}

	function restartSpatialAudio(){
		audioElements[4].currentTime = 0;
	}

	function playSpatialAudio(){
		audioElements[4].play();
	}

	function pauseSpatialAudio(){
		audioElements[4].pause();
	}

	function getUploadAudioElm(){
		return audioElements[6];
	}

	function getAudioElements(){
		return audioElements;
	}

	function setCurrentAudioCoverlayInfo(){
		var audioLinkID = currentAudio.getAttribute('data-audiolinkid');
		var audioDescription = currentAudio.getAttribute('data-audiodescription');
		var coverlayTextMsg = currentAudio.getAttribute('data-coverlay');
		// Coverlay text
		coverlayText.updateUI(coverlayTextMsg);
		// Coverlay share buttons
		coverlayShareButtons.updateUI([audioLinkID, audioDescription]);
	}

	return {
		init: init,
		setVolume: setVolume,
		channelTrigger: channelTrigger,
		fadeIn: fadeIn,
		fadeOut: fadeOut,
		getAudioPlayingWithinFreqRange: getAudioPlayingWithinFreqRange,
		setAudioPlayingWithinFreqRange: setAudioPlayingWithinFreqRange,
		getFromStation: getFromStation,
		restartAboutStation: restartAboutStation,
		restartSpatialAudio: restartSpatialAudio,
		setFromStation: setFromStation,
		setCurrentAudioCoverlayInfo: setCurrentAudioCoverlayInfo,
		getFromPlaylistAdvance: getFromPlaylistAdvance,
		setFromPlaylistAdvance: setFromPlaylistAdvance,
		getAudioElements: getAudioElements
	};
	
})(); //audioContainer

export {audioContainer};