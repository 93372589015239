import {radioKnobController} from './radioKnobController.js';

// Radio knob
var radioKnob = (function(){

	//data
	var angle = 0;
	var autoRotateAngle;

	//cache DOM
	var $el = $('#radio-knob');
	var $knobBackgroundImage = $el.find('#knobBackground img');
	var $knobRotate = $el.find('#knobRotate');
	var $knobRotateImage = $knobRotate.find('img');
	var $knobRotateNotches = $el.find('#knobRotateNotches');
	var $knobRotateNotchesImage = $knobRotateNotches.find('img');

	function init() {
		$knobBackgroundImage.attr("src","images/knob/knob-back.png");
		$knobRotateImage.attr("src","images/knob/knob-front.svg");
		$knobRotateNotchesImage.attr("src","images/knob/knob-notches-space.svg");
	}

	function _render() {
		$knobRotate.css("transform", "translate(-50%, 5%) rotate(" + angle + "deg)");
		$knobRotateNotches.css("transform", "translate(-50%, 5%) rotate(" + angle + "deg)");
	}

	function initJqueryRotate() {
		// jQueryRotate
		/*Source: https://github.com/wilq32/jqueryrotate/*/
		var rotation = function (){
			$knobRotateImage.rotate({
				angle: 0,
				animateTo: autoRotateAngle,
				//callback: rotation,
				easing: function (x,t,b,c,d){ // t: current time, b: begInnIng value, c: change In value, d: duration
					return c*(t/d)+b;
				}
			});
			$knobRotateNotchesImage.rotate({
				angle: 0,
				animateTo: autoRotateAngle,
				//callback: rotation,
				easing: function (x,t,b,c,d){ // t: current time, b: begInnIng value, c: change In value, d: duration
					return c*(t/d)+b;
				}
			});
		}
		rotation();
	}

	function startRotate(data){
		var channel = radioKnobController.getChannel();
		if (channel < data){
			autoRotateAngle = 360;
		} else if (channel > data){
			autoRotateAngle = -360;
		}
		initJqueryRotate();
	}

	function stopRotate(){
		$knobRotateImage.stopRotate();
	}

	function updateUI(data) {
		angle = data;
		_render();
	}

	return {
		init: init,
		updateUI: updateUI,
		startRotate: startRotate,
		stopRotate: stopRotate
	};

})(); //radioKnob

export {radioKnob};