import normalizeWheel from 'normalize-wheel';
import {states} from './states.js';
import {options} from './options.js';
import {radioKnobController} from './radioKnobController.js';
import {phoneRotate} from './phoneRotate.js';

// Mouse scroll
var mouseScroll = (function(){

	//data
	var anguloAtual = 0;
	var dialSpeedLimit;
	var pointerSpeedLimit;

	//bind events
	if (!options.disableMouseScroll){
		//document.addEventListener('wheel', scroll);
		// Source: https://stackoverflow.com/questions/42101723/unable-to-preventdefault-inside-passive-event-listener
		document.addEventListener("wheel", scroll, { passive: false });
	}

	function scroll(event){
		if (!states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen && !states.enterPageOpen && !states.autoTuning){

			event.preventDefault();

			// Normalize mouse wheel data
			const normalized = normalizeWheel(event);
			//console.log(normalized.pixelX, normalized.pixelY);
		
			// Limit dial rotation speed
			dialSpeedLimit = normalized.pixelY * 0.10;
			if (dialSpeedLimit < -40) {
				dialSpeedLimit = -40;
			}
			if (dialSpeedLimit > 40) {
				dialSpeedLimit = 40;
			}
			anguloAtual += dialSpeedLimit;

			// Limit pointer speed
			pointerSpeedLimit = normalized.pixelY * 0.04;

			// Sync anguloAtual local variables
			radioKnobController.setAnguloAtual(anguloAtual);
			phoneRotate.setAnguloAtual(anguloAtual);

			// Call switchChannel()
			radioKnobController.switchChannel(pointerSpeedLimit);
		}
	}

	function setAnguloAtual(data) {
		anguloAtual = data;
	}

	return {
		setAnguloAtual: setAnguloAtual
	};

})(); //mouseScroll

export {mouseScroll};