const bowser = require('bowser');
import {states} from './states.js';
import {app} from './app.js';
import {marquee} from './marquee.js';
import {chart} from './chart.js';
import {audioStatic} from './audioStatic.js';
import {playButton} from './playButton.js';
import {omnitoneHOA} from './omnitoneHOA.js';

// Enter page
var enterPage = (function(){

	//states
	var userGestureToken = false;

	//data
	var loaded = false;

	//cache DOM
	var $el = $('#enter-page');
	var $enterButton = $el.find('#enterPageEnterButton');
	var $img = $el.find('img');

	//bind events
	$enterButton.on('click', displayApp);

	function init() {
		// Only load svg if MacOS is detected to resolve different OS default fonts
		if (bowser.osname == "macOS"){
			console.log("MacOS detected");
			$img.attr("src","images/logo/Shortwave-logo.svg");
		} else {
			$img.attr("src","images/logo/Shortwave-logo.png");
		}
	}

	function fadeIn() {
		if (!loaded){
			loaded = true;
			console.log("loaded", loaded);
			$el.addClass('visible');
			$el.fadeTo(1000, 1);
		}
	}

	function displayApp(){
		// Gesture input
		gestureInput();
		// Disable enter button
		$enterButton.addClass("avoid-clicks");
		if (!states.onStation){
			// Update marquee message
			marquee.updateUI("");
		}
		// Fade out enter page
		$el.fadeOut(1000, function() {
			// Fade in app
			app.fadeIn();
			// Fade in chart
			chart.fadeIn();
		});
		// Play audio staic
		audioStatic.play();
		// Toggle play
		playButton.togglePlay();
		// Play/pause omnitone audio on enter page click to fix Safari playback issue
		omnitoneHOA.playOmnitone();
		omnitoneHOA.stopOmnitone();
		// Play omnitone if on spatial audio station from hashtagValue
		if (states.onSpatialAudioStation && !states.omnitonePlaying){
			omnitoneHOA.playOmnitone();
		}
	}

	function gestureInput(){
		if (!userGestureToken){
			userGestureToken = true;
			console.log("userGestureToken", userGestureToken);
		}
	}

	function setEnterPageOpen(data){
		states.enterPageOpen = data;
		console.log("states.enterPageOpen", states.enterPageOpen);
	}

	return {
		init: init,
		fadeIn: fadeIn,
		setEnterPageOpen: setEnterPageOpen
	};

})(); //enterPage

export {enterPage};