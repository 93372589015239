import {aboutCoverlayInfo} from '../database.js';

// Coverlay text
var coverlayText = (function(){

	//states
	var aboutCoverlayOpen = false;
	var hasAjaxPresentation = false;
	
	//data
	var coverlayTextMsg = '';
	var aboutCoverlayTextMsg = "<h1>"+aboutCoverlayInfo.coverlayHeading+"</h1><p style='padding-top:20px'>"+aboutCoverlayInfo.coverlayParagraph+"</p>";

	//cache DOM
	var $el = $('#coverlay-text');

	function _render() {
		if (aboutCoverlayOpen){
			$el.html(aboutCoverlayTextMsg);
		} else {
			$el.html(coverlayTextMsg);
		}
	}

	function updateUI(data) {
		coverlayTextMsg = data;
		_render();
	}

	function showAboutCoverlay(boolean) {
		aboutCoverlayOpen = boolean;
		console.log("aboutCoverlayOpen", aboutCoverlayOpen);
		_render();
	}

	function detectAjaxPresentation(){
		if (!aboutCoverlayOpen){
			hasAjaxPresentation = coverlayTextMsg.includes('"requestAjaxOverlay"');
			console.log("hasAjaxPresentation", hasAjaxPresentation);
			return hasAjaxPresentation;
		} else {
			return false;
		}
	}

	return {
		updateUI: updateUI,
		showAboutCoverlay: showAboutCoverlay,
		detectAjaxPresentation: detectAjaxPresentation
	};

})(); //coverlayText

export {coverlayText};