import {states} from './states.js';
import {playButton} from './playButton.js';

// Spotlight icon
var spotlightIcon = (function(){

	//cache DOM
	var $el = $('#spotlightIcon');
	var $image = $el.find('img');

	//bind events
	// Note: target="_blank" click in HTML
	$el.on('click', pauseAudio);

	function init(){
		_render();
	}

	function _render() {
		$image.attr("src","images/icons/search.svg");
	}

	function pauseAudio(){
		// If playing, pause audio
		if (states.playing){
			playButton.togglePlay();
		}
	}

	return {
		init: init
	}

})(); //spotlightIcon

export {spotlightIcon};