// Libraries
const bowser = require('bowser');

// Mode
const productionMode = process.env.PRODUCTION || "true";

// Root URL
let rootURL = process.env.ROOT_URL || "https://cdsshortwave.org/";

// Mobile device
let mobileDevice = false;
if (bowser.mobile || bowser.tablet){
	mobileDevice = true;
}
console.log("mobileDevice", mobileDevice);

// Valid desktop browser
let validDesktopBrowser = false;
if (!bowser.msie){
	validDesktopBrowser = true;
}
console.log("validDesktopBrowser", validDesktopBrowser);

// Valid mobile browser
let validMobileBrowser = false;
if (mobileDevice){
	validMobileBrowser = true;
}
console.log("validMobileBrowser", validMobileBrowser);

// S3 Path
let S3_PATH = process.env.S3_PATH || "https://s3.amazonaws.com/cdsshortwave/audio/";
// If mobile device is detected, change S3 bucket location
if (mobileDevice) {
	S3_PATH = process.env.S3_PATH_MOBILE || "https://s3.amazonaws.com/cdsshortwave/audio-mobile/";
}

export {
	productionMode,
	rootURL,
	mobileDevice,
	S3_PATH,
	validDesktopBrowser,
	validMobileBrowser
};