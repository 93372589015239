import {states} from './states.js';
import {marquee} from './marquee.js';
import {qrCodeModal} from './qrCodeModal.js';

// Remote status
var remoteStatus = (function(){

	//cache DOM
	var $el = $('#remote-status');
	var $notPaired = $el.find('#remote-status-not-paired');
	var $paired = $el.find('#remote-status-paired');

	//bind events
	$el.on('click', toggleQrCodeModal);

	function init(){
		if (states.remotePaired){
			$paired.show();
		} else {
			$notPaired.show();
		}
	}

	function _render() {
		// Update marquee
		marquee.updateUI("");
		if (states.remotePaired){
			$notPaired.fadeOut(1000);
			$paired.delay(1000).fadeIn(1000);
			qrCodeModal.toggleQrCodeModal();
			$el.addClass("remotePaired");
		} else {
			$paired.fadeOut(1000);
			$notPaired.delay(1000).fadeIn(1000);
			$el.removeClass("remotePaired");
		}
	}

	function toggleQrCodeModal() {
		if (!states.remotePaired){
			qrCodeModal.toggleQrCodeModal();
		}
	}

	function updateUI() {
		_render();
	}

	return {
		init: init,
		updateUI: updateUI
	};

})(); //remoteStatus

export {remoteStatus};