import {stations} from '../database.js';
import {radioKnobController} from './radioKnobController.js';
import {pointer} from './pointer.js';
import {moveToStation} from './moveToStation.js';
import {audioContainer} from './audioContainer.js';

// Hashtag value
var hashtagValue = (function(){

	//states
	var hashtagValueValid = false;
	var hashtagValueNearbyStation = false;

	//data
	var hashtagValue;
	var minFreq = 1;
	var maxFreq = 15;

	// Map number ranges
	// Source: https://gist.github.com/xposedbones/75ebaef3c10060a3ee3b246166caab56
	Number.prototype.map = function (in_min, in_max, out_min, out_max) {
		return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
	};

	function init(){
		checkHashtagValue();
	}

	function checkHashtagValue(){
		// Parse hashtag value from URL and return float
		hashtagValue = parseFloat(window.location.href.split('#')[1]);
		console.log("hashtagValue", hashtagValue);

		// If hashtagValue is within frequency range
		if (hashtagValue >= minFreq && hashtagValue <= maxFreq){
			hashtagValueValid = true;
			console.log("hashtagValueValid", hashtagValueValid);

			// Check if hashtagValue is within frequency range of a station except ghost stations
			for (var i=0; i < stations.length-2; i++) {
				if (hashtagValue >= Math.round((stations[i].freq-0.5) * 100) / 100 && hashtagValue <= Math.round((stations[i].freq+0.5) * 100) / 100){
					hashtagValueNearbyStation = true;
					console.log("hashtagValueNearbyStation", hashtagValueNearbyStation);
				}
			}

			/*// Check if hashtagValue matches a freq value in stations array without ghost stations
			var hashtagValueOnStation = stations.map(a => a.freq).slice(-0, -2).includes(hashtagValue);
			console.log("hashtagValueOnStation", hashtagValueOnStation);*/
		} else {
			console.log("Hashtag value invalid");
		}

		// Must be called after hashtagValueNearbyStation is set for hashtag value to work
		audioContainer.init();

		if (hashtagValueValid){
			goToInitialChannel(hashtagValue);
		}
	}

	function goToInitialChannel(data){
		// Get hashtagValue
		var initialChannel = data;

		// Get pointerPosFinal
		var pointerPosFinal = radioKnobController.getPointerPosFinal();
		console.log("pointerPosFinal", pointerPosFinal);

		// Set pointer position
		var num = initialChannel;
		var mappedNum = num.map(minFreq, maxFreq, 0, pointerPosFinal);
		pointer.updateUI(mappedNum);

		// Update pointer position value in radioKnobController
		radioKnobController.setPointerPosition(mappedNum);

		// Set channel
		radioKnobController.setChannel(initialChannel);

		// Go to initial channel
		moveToStation.goToChannel();
	}

	function getHashtagValueNearbyStation() {
		return hashtagValueNearbyStation;
	}

	function setHashtagValueNearbyStation(data) {
		hashtagValueNearbyStation = data;
		console.log("hashtagValueNearbyStation", hashtagValueNearbyStation);
	}

	return {
		init: init,
		getHashtagValueNearbyStation: getHashtagValueNearbyStation,
		setHashtagValueNearbyStation: setHashtagValueNearbyStation
	};

})(); //hashtagValue

export {hashtagValue};