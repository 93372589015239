import {moveToStation} from './moveToStation.js';

// Pointer
var pointer = (function(){

	//data
	var pointerPosition = 0;
	var animationDuration = 1000;

	//cache DOM
	var $el = $('#pointer');

	function _render() {
		$el.css("left", pointerPosition);
	}

	function animate(data){
		$el.animate({
			left: data+"px"
		}, animationDuration, function() {
			moveToStation.goToChannel();
		});
	}

	function updateUI(data) {
		pointerPosition = data;
		_render();
	}

	return {
		updateUI: updateUI,
		animate: animate
	};

})(); //pointer

export {pointer};