import {S3_PATH} from '../config.js';
import {playlistStatic} from '../playlists.js';
import {options} from './options.js';

// Audio static
var audioStatic = (function(){

	//cache DOM
	var $el = $('#audio-static'); //returns jQuery Object
	var domEl = $el[0]; //returns HTML DOM Object
	var defaultVolumeFadeDuration = 1000;
	var volumeFadeDuration = defaultVolumeFadeDuration;
	
	function init() {
		domEl.innerHTML = '<source src="' + S3_PATH + playlistStatic[0].srclink+'.mp3' + '" type="audio/mpeg">';
		domEl.load();
		domEl.volume = 0;
		domEl.loop = true;
	}

	function play() {
		domEl.play();
	}

	function pause() {
		domEl.pause();
	}

	function volume(data) {
		domEl.volume = data;
	}

	function fadeIn(data) {
		if (typeof data === "number") {
			volumeFadeDuration = data;
		}
		$el.animate({volume: options.volumeAudio}, volumeFadeDuration);
		volumeFadeDuration = defaultVolumeFadeDuration;
	}

	function fadeOut(data) {
		if (typeof data === "number") {
			volumeFadeDuration = data;
		}
		$el.animate({volume: 0}, volumeFadeDuration);
		volumeFadeDuration = defaultVolumeFadeDuration;
	}

	return {
		init: init,
		play: play,
		pause: pause,
		volume: volume,
		fadeIn: fadeIn,
		fadeOut: fadeOut
	};

})(); //audioStatic

export {audioStatic};