import {stations} from '../database.js';
import {radioKnobController} from './radioKnobController.js';
import {moveToStation} from './moveToStation.js';
import {audioContainer} from './audioContainer.js';

// Wordmark
var wordmark = (function(){

	//cache DOM
	var $el = $('#wordmark');

	//bind events
	$el.on('click', moveToAboutStation);

	function moveToAboutStation() {
		var channel = radioKnobController.getChannel();
		console.log("channel", channel);
		// If not on about station, move to about station
		if (channel != stations[1].freq){
			setTimeout(function(){
				// Restart about station audio
				audioContainer.restartAboutStation();
			}, 1000);
			moveToStation.start(stations[1].freq);
		}
	}

	return {
		moveToAboutStation: moveToAboutStation
	};

})(); //wordmark

export {wordmark};