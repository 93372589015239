import {states} from './states.js';
import {omnitoneHOA} from './omnitoneHOA.js';

// Right side tab omnitone
var rightSideTabOmnitone = (function(){

	//data
	var rightSideTabAnimationComplete = true;
	var rightSideTabAnimationDuration = 700;
	var elWidth;
	var num;
	var mappedNum;

	//cache DOM
	var $el = $('#right-side-tab-omnitone');
	var $knob = $el.find('.knob');

	function init(){
		// Get element width
		elWidth = $el.width();

		if (states.onSpatialAudioStation){
			states.rightSideTabOmnitoneOpen = true;
			console.log("states.rightSideTabOmnitoneOpen", states.rightSideTabOmnitoneOpen);
		} else {
			states.rightSideTabOmnitoneOpen = false;
			console.log("states.rightSideTabOmnitoneOpen", states.rightSideTabOmnitoneOpen);
			// Hide element
			$el.css("margin-right", -elWidth-3+"px");
		}

		// Init jQuery Knob
		initJqueryKnob();
	}

	function _render(){
		if (!states.rightSideTabOmnitoneOpen){
			states.rightSideTabOmnitoneOpen = true;
			console.log("states.rightSideTabOmnitoneOpen", states.rightSideTabOmnitoneOpen);
			rightSideTabAnimationComplete = false;
			console.log("rightSideTabAnimationComplete", rightSideTabAnimationComplete);
			// Animate tab
			$el.animate({
				marginRight: '0px'
			}, rightSideTabAnimationDuration, "easeInOutQuart", function() {
				rightSideTabAnimationComplete = true;
				console.log("rightSideTabAnimationComplete", rightSideTabAnimationComplete);
			});
		} else {
			states.rightSideTabOmnitoneOpen = false;
			console.log("states.rightSideTabOmnitoneOpen", states.rightSideTabOmnitoneOpen);
			// Animate tab
			$el.animate({
				marginRight: -elWidth-3+"px"
			}, rightSideTabAnimationDuration, "easeInOutQuart", function() {
				rightSideTabAnimationComplete = true;
				console.log("rightSideTabAnimationComplete", rightSideTabAnimationComplete);
			});
		}
	}

	function initJqueryKnob() {
		// jQuery Knob
		// Source: https://github.com/aterrien/jQuery-Knob
		$knob.knob({
			'width':100,
			'height':100,
			'min':0,
			'max':360,
			'step':1,
			'fgColor':'#222222',
			'thickness':.3,
			change : function (value) {
				console.log("change : " + value);
				// Call Omnitone function
				omnitoneHOA.onDirectionChange();
			}
		});
	}

	// Mapping number ranges
	// Source: https://gist.github.com/xposedbones/75ebaef3c10060a3ee3b246166caab56
	Number.prototype.map = function (in_min, in_max, out_min, out_max) {
		return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
	};

	function rotateAzimuthControlKnob(data){
		if (states.onSpatialAudioStation){
			// Map number ranges
			num = data;
			//mappedNum = num.map(0, 360, -180, 180);
			mappedNum = num.map(0, 360, 360, 0);
			console.log("mappedNum = "+mappedNum);

			// Set value of slider knob
			$knob
				.val(mappedNum)
				.trigger('change');

			// Call Omnitone function
			omnitoneHOA.onDirectionChange();
		}
	}

	function toggleTab() {
		_render();
	}

	return {
		init: init,
		toggleTab: toggleTab,
		rotateAzimuthControlKnob: rotateAzimuthControlKnob
	};

})(); //rightSideTabOmnitone

export {rightSideTabOmnitone};