import {states} from './states.js';
import {coverlay} from './coverlay.js';
import {coverlayText} from './coverlayText.js';

// Info button
var infoButton = (function(){

	//cache DOM
	var $el = $('#info-button');
	var $link = $el.find('a');
	var $image = $el.find('img');

	//bind events
	$link.on('click', toggleCoverlay);

	function init() {
		$image.attr("src","images/icons/information-variant.svg");
		// Disable
		$link.addClass("avoid-clicks");
	}

	function _render() {
		var hasAjaxPresentation = coverlayText.detectAjaxPresentation();
		if (states.onStation){
			if (hasAjaxPresentation){
				$image.attr("src","images/icons/information-variant-red.svg");
			} else {
				$image.attr("src","images/icons/information-variant.svg");
			}
			// Enable
			$el.fadeTo( 200, 1 );
			$link.removeClass("avoid-clicks");
		} else {
			if (hasAjaxPresentation){
				$image.attr("src","images/icons/information-variant.svg");
			}
			// Disable
			$el.fadeTo( 500, 0.3 );
			$link.addClass("avoid-clicks");
		}
	}

	function toggleCoverlay() {
		//Note: not needed anymore, coverlay is set to current audio info when coverlay closes
		/*if (states.onStation){
			// Set current audio coverlay info
			audioContainer.setCurrentAudioCoverlayInfo();
		}*/
		coverlay.toggleCoverlay();
	}

	function updateUI() {
		_render();
	}

	return {
		init: init,
		updateUI: updateUI,
		toggleCoverlay: toggleCoverlay
	};

})(); //infoButton

export {infoButton};