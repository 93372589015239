import {stations} from '../database.js';
import {audioContainer} from './audioContainer.js';

// Station chart
var stationChart = (function(){

	//data
	var audioElements = [];
	var nowPlaying = [];
	var redLightMarkup = "<span class='redLightStationChart'></span>";

	//cache DOM
	var $el = $('#stationChart');
	var $tbody = $el.find('tbody');

	function initTableRowElements(){
		var str = "";
		// Construct HTML markup
		for (let i=0; i < stations.length-2; i++) {
			str = str + '<tr>'
							+ '<td scope="row" data-label="Frequency"><span>' + stations[i].freq + '</span></td>'
							+ '<td data-label="Station"><span>' + stations[i].stationinfo + '</span></td>'
							+ '<td data-label="Now Playing"><span class="nowPlaying"></span></td>'
						+ '</tr>';
		};
		// Append to DOM
		$tbody.html(str);
		// Add class to every other row
		$tbody.find('tr:even').addClass("odd");
		// Create array of audio description containers
		nowPlaying = $( ".nowPlaying" ).toArray();
		//console.log("nowPlaying", nowPlaying);
	}

	function init(){
		audioElements = audioContainer.getAudioElements();
		initTableRowElements();
		// Poplate initial audio descriptions
		for (let i=0; i < stations.length-2; i++) {
			var audioDescription = audioElements[i].getAttribute('data-audiodescription');
			updateUI(i, audioDescription);
		}
	}

	function _render(elm, msg, hasAjaxPresentation) {
		elm.innerHTML = msg;
		if (hasAjaxPresentation !== null){
			// Convert HTML elm to jQuery element
			var $elm = $(elm);
			// Remove red light from DOM
			$elm.next('span').remove();
			// If hasAjaxPresentation is true, insert red light
			if (hasAjaxPresentation){
				$(redLightMarkup).insertAfter(elm);
			}
		}
	}

	function updateUI(i, msg) {
		var elm = nowPlaying[i];
		var hasAjaxPresentation = audioElements[i].getAttribute('data-coverlay').includes('"requestAjaxOverlay"');
		_render(elm, msg, hasAjaxPresentation);
	}

	return {
		init: init,
		updateUI: updateUI
	};

})(); //stationChart

//stationChart.updateUI(0, "msg");

export {stationChart};