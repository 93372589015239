const QRCode = require("@keeex/qrcodejs-kx");
import {rootURL} from '../config.js';
import {states} from './states.js';
import {options} from './options.js';
import {passwordProtectForm} from './passwordProtectForm.js';
import {playButton} from './playButton.js';

// QR code modal
var qrCodeModal = (function(){

	//data
	var qrcodeURL = rootURL+"remote.html";
	var qrCodeModalFadeComplete = true;
	var qrCodeModalFadeDuration = 1200;
	var wasPlaying = false;

	//cache DOM
	var $el = $('#qr-code-modal');
	var $overlay = $el.find('#overlayQrCodeModal');
	var $wrap = $el.find('#wrapQrCode');
	var $qrcode = $el.find('#qrcode');
	var qrcodeDomEl = $el.find('#qrcode')[0];
	var $closeButton = $el.find('#closeQrCodeModal');

	//bind events
	$closeButton.on('click', toggleQrCodeModal);

	// Init QR code generator
	var qrcodeModalGenerate = new QRCode(qrcodeDomEl, {
		//text: "https://www.google.com/",
		width: 250,
		height: 250,
		colorDark : "#000000",
		colorLight : "#ffffff",
		correctLevel : QRCode.CorrectLevel.H
	});

	function _render() {
		if (!states.qrCodeModalOpen && !states.remotePaired) {
			states.qrCodeModalOpen = true;
			console.log("states.qrCodeModalOpen", states.qrCodeModalOpen);
			qrCodeModalFadeComplete = false;
			console.log("qrCodeModalFadeComplete", qrCodeModalFadeComplete);
			// Animating opacity fixes flicker when using fadeIn in Safari
			$overlay.show();
			$overlay.fadeTo(qrCodeModalFadeDuration, 0.85);
			$wrap.show();
			$wrap.fadeTo(qrCodeModalFadeDuration, 1, function() {
				qrCodeModalFadeComplete = true;
				console.log("qrCodeModalFadeComplete", qrCodeModalFadeComplete);
			});

			// If playing, stop playing and set wasPlaying to true
			if (states.playing){
				playButton.togglePlay();
				wasPlaying = true;
				console.log("wasPlaying", wasPlaying);
			} else {
				wasPlaying = false;
				console.log("wasPlaying", wasPlaying);
			}
		}
		else if (states.qrCodeModalOpen) {
			qrCodeModalFadeComplete = false;
			console.log("qrCodeModalFadeComplete", qrCodeModalFadeComplete);
			// Animating opacity fixes flicker when using fadeIn in Safari
			$overlay.fadeTo(qrCodeModalFadeDuration, 0, function() {
				$overlay.hide();
			});
			$wrap.fadeTo(qrCodeModalFadeDuration, 0, function() {
				$wrap.hide();
				// Ensure immediate scroll triggers offStationEvents()
				setTimeout(function(){
					states.qrCodeModalOpen = false;
					console.log("states.qrCodeModalOpen", states.qrCodeModalOpen);
					qrCodeModalFadeComplete = true;
					console.log("qrCodeModalFadeComplete", qrCodeModalFadeComplete);
				}, 100);
			});
			// If not playing and wasPlaying is true, resume playing
			if (!states.playing && wasPlaying){
				playButton.togglePlay();
			}
		}
	}

	// Note: called after connectToRoom socket event or password protect form
	function init(data){
		if (options.passwordProtect){
			var passwordCorrect = passwordProtectForm.getPasswordCorrect();
			console.log("passwordCorrect", passwordCorrect);
			if (passwordCorrect){
				makeQRcode(data);
			} else {
				console.log("Password not entered");
			}
		} else {
			makeQRcode(data);
		}
	}

	function makeQRcode(data) {
		if (options.newSocketRoomOnRemoteDisconnect){
			clearQRcode();
		}
		// Make QR code
		qrcodeModalGenerate.makeCode(qrcodeURL+"#"+data);
	}

	function clearQRcode() {
		// Clear QR code
		qrcodeModalGenerate.clear();
	}

	function toggleQrCodeModal() {
		if (qrCodeModalFadeComplete){
			_render();
		}
	}

	return {
		toggleQrCodeModal: toggleQrCodeModal,
		init: init
	};

})(); //qrCodeModal

export {qrCodeModal};