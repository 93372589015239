var states = {
	socketRoomCode: null,
	enterPageOpen: true,
	remotePaired: false,
	onStation: false,
	nearbyStation: false,
	onSpatialAudioStation: false,
	onGhostStation: false,
	playing: false,
	omnitonePlaying: false,
	captionsOn: false,
	autoTuning: false,
	stationStuck: false,
	coverlayOpen: false,
	chartOpen: false,
	chartOpenStationChartActive: false,
	qrCodeModalOpen: false,
	rightSideTabMessageOpen: false,
	rightSideTabOmnitoneOpen: false
};

export {states};