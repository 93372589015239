import {
	database_radio_only,
	database_all,
	stations
} from './database.js';

// Init Arrays
var playlists = [];
var playlistStatic = [];

for (var i=0; i < stations.length; i++) {
	playlists.push([]);
}

// Setup arrays

// Mix 1
var playlist0setup = [
	"when-the-truth-came-calling",
	"the-forest",
	"blackspace",
	"witness-evidence-and-documentary-art",
	"shadows",
	"binnegoed",
	"birth-stories",
	"a-traditional-future",
	"small-town-survival",
	"who-we-are-what-we-do-and-why",
	"nourish",
	"my-white-friends",
	"sudden-flowers",
	"limitless-africans",
	"the-lost-city-of-new-york",
	"make-believe",
	"bragtown-summers",
	"brambles",
	"on-the-bus",
	"langum-fork",
	"five-miles-out-of-town",
	"german-blues",
	"jodie-built-a-stovepipe-hat",
	"maze"
];
// Welcome
var playlist1setup = [
	"welcome"
];
// On Race and Whiteness
var playlist2setup = [
	"lo-que-te-di",
	"the-white-and-black-of-it",
	"asians-in-america",
	"marked-or-unmarked",
	"ignorance-isnt-bliss"
];
// Mix 2
var playlist3setup = [ 
	"one-with-others",
	"theater-of-war",
	"rocky-river-home",
	"25th-anniversary-honorees",
	"half-caked",
	"building-on-the-border",
	"sea-culture",
	"my-dad-and-me-in-three-songs",
	"the-invention-of-race",
	"himpathy",
	"the-rough-south-of-larry-brown",
	"why-documentary-matters",
	"gravity-is-stronger-here",
	"the-bathers",
	"on-eating-lotus",
	"seeing-the-world-in-a-different-way",
	"the-weather-and-a-place-to-live",
	"an-everyday-affair",
	"pod-of-safety",
	"something-strange",
	"lake-erie",
	"in-the-pines",
	"shortinin-bread",
	"road-through-midnight"
];
// Spatial Audio
var playlist4setup = [
	"sounds-from-the-cds-porch"
];
// Full Frame Festival Speakeasies
var playlist5setup = [
	"docs-so-white-representation-agency-and-authorship",
	"documentary-in-the-time-of-fake-news",
	"docs-so-white-the-gatekeepers",
	"running-with-scissors",
	"some-other-lives-of-time",
	"the-path-forward",
	"moving-from-ally-to-accomplice",
	"framing-the-conversation"
];
// Audio Exhibition
var playlist6setup = [
	"warmth",
	"igba-otutu",
	"ice-skating",
	"prince-charming",
	"night-crickets",
	"jet-wash",
	"carbonated-beverage-on-ice",
	"overnight-train-ride",
	"roosting-birds",
	"where-are-you-going",
	"night-walk",
	"hypothetically-together"
];
// Mix 3
var playlist7setup = [
	"anytown-usa",
	"immersion",
	"severance",
	"remembering-jim-crow",
	"are-you-experienced",
	"youthline",
	"losing-yourself",
	"hijabis",
	"one-hurricane-season",
	"next-world-tarot",
	"cds-courses",
	"interactions-and-impact",
	"iraq-perspectives",
	"legendary-inside-the-house-ballroom-scene",
	"on-being-seen",
	"test-of-faith",
	"city-under-one-roof",
	"no-santa",
	"administrations-of-lunacy",
	"simple-dreams",
	"up-for-the-fight",
	"cumberland-gap",
	"little-dove",
	"zollies-retreat"
];
// Ghost 1
var playlist8setup = [
	"lost-signal-01",
	"lost-signal-12",
	"lost-signal-03",
	"lost-signal-14",
	"lost-signal-05",
	"lost-signal-16",
	"lost-signal-07",
	"lost-signal-18",
	"lost-signal-09",
	"lost-signal-20"
];
// Ghost 2
var playlist9setup = [
	"lost-signal-11",
	"lost-signal-02",
	"lost-signal-13",
	"lost-signal-04",
	"lost-signal-15",
	"lost-signal-06",
	"lost-signal-17",
	"lost-signal-08",
	"lost-signal-19",
	"lost-signal-10"
];
// Static
var playlistStaticSetup = [
	"static"
];

// Find object by key
function findObjectByKey(array, key, value) {
	for (var i = 0; i < array.length; i++) {
		if (array[i][key].toLowerCase() === value.toLowerCase()) {
			return array[i];
		}
	}
	return null;
}

// Push found object to array
function pushObjToArray(array, key, value, database) {
	if (typeof value !== "undefined" && String(value).length > 0){

		var obj = findObjectByKey(database, key, value);

		if (obj !== null){
			array.push(obj);
		}
		else if (obj == null){
			console.log("Null object returned");
		}
	}
}

// Send info from setup arrays
function sendArrayInfo(arraySetup, arrayPush, database, key) {
	for (var i=0; i < arraySetup.length; i++) {
		var value = arraySetup[i];
		pushObjToArray(
			arrayPush,
			key,
			value,
			database
		);
	}
}

// Build arrays
sendArrayInfo(playlist0setup, playlists[0], database_all, 'audiolinkid');
sendArrayInfo(playlist1setup, playlists[1], database_all, 'audiolinkid');
sendArrayInfo(playlist2setup, playlists[2], database_all, 'audiolinkid');
sendArrayInfo(playlist3setup, playlists[3], database_all, 'audiolinkid');
sendArrayInfo(playlist4setup, playlists[4], database_radio_only, 'audiolinkid'); // Spatial audio object found in database_radio_only array
sendArrayInfo(playlist5setup, playlists[5], database_all, 'audiolinkid');
sendArrayInfo(playlist6setup, playlists[6], database_all, 'audiolinkid');
sendArrayInfo(playlist7setup, playlists[7], database_all, 'audiolinkid');
sendArrayInfo(playlist8setup, playlists[8], database_all, 'audiolinkid');
sendArrayInfo(playlist9setup, playlists[9], database_all, 'audiolinkid');
sendArrayInfo(playlistStaticSetup, playlistStatic, database_radio_only, 'audiolinkid'); // Static audio object found in database_radio_only array

// Logs
/*for (var i=0; i < playlists.length; i++) {
	console.log("playlist"+i, playlists[i]);
}*/

export {
	playlists,
	playlistStatic
}

