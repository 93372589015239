import {states} from './states.js';
import {socketEmit} from './socketEmit.js';
import {playButton} from './playButton.js';
import {marquee} from './marquee.js';
import {audioInfo} from './audioInfo.js';
import {closedCaptionsIcon} from './closedCaptionsIcon.js';
import {audioContainer} from './audioContainer.js';
import {coverlayText} from './coverlayText.js';
import {coverlayShareButtons} from './coverlayShareButtons.js';
import {radioKnobController} from './radioKnobController.js';

// Coverlay
var coverlay = (function(){

	//data
	var channel;
	var scrollbarWidth;
	var ajaxClosing = false;
	var hasAjaxPresentation = false;
	var wasPlaying = false;
	var coverlayFadeComplete = true;
	var coverlayFadeDuration = 1200;

	//cache DOM
	var $el = $('#coverlay');
	var $overlay = $el.find('#overlayCoverlay');
	var $wrap = $el.find('#wrapCoverlay');
	var wrapDomEl = $el.find('#wrapCoverlay')[0];
	var $content = $wrap.find('#coverlayContent');
	var $closeButton = $el.find('#closeCoverlayButton');

	//bind events
	$closeButton.on('click', toggleCoverlay);
	window.addEventListener('resize', windowResize);

	function init(){
		detectScrollbarWidth();
	}
	
	function _render() {
		if (!states.coverlayOpen){
			// Update remote UI, disable or enable open ajax button
			var hasAjaxPresentation = coverlayText.detectAjaxPresentation();
			socketEmit.send("returnHasAjaxPresentation", hasAjaxPresentation);

			states.coverlayOpen = true;
			console.log("states.coverlayOpen", states.coverlayOpen);
			socketEmit.state("coverlayOpen", states.coverlayOpen);

			coverlayFadeComplete = false;
			console.log("coverlayFadeComplete", coverlayFadeComplete);

			// If playing, stop playing and set wasPlaying to true
			if (states.playing){
				playButton.togglePlay();
				wasPlaying = true;
				console.log("wasPlaying", wasPlaying);
			} else {
				wasPlaying = false;
				console.log("wasPlaying", wasPlaying);
			}

			// Animating opacity fixes flicker when using fadeIn in Safari
			$overlay.show();
			$overlay.fadeTo(coverlayFadeDuration, 0.85);
			$wrap.show();
			$wrap.fadeTo(coverlayFadeDuration, 1, function() {
				coverlayFadeComplete = true;
				console.log("coverlayFadeComplete", coverlayFadeComplete);
			});
			$wrap.scrollTop( 0 );

			// Fade out marquee and audio info
			marquee.fadeOut();
			audioInfo.fadeOut();

			// Enable closedCaptionsIcon animation
			closedCaptionsIcon.enableAnimation();

			// If vertical scrollbar is detected
			if($wrap.hasScrollbar()){
				// Adjust close button and close caption icon positions based on scroll bar width
				$closeButton.css({"margin-right": scrollbarWidth+"px"});
				closedCaptionsIcon.moveIcon(scrollbarWidth);
				// Position coverlay content to top of window
				$content.removeClass("center");
			}
			else {
				$closeButton.css({"margin-right": "0px"});
				closedCaptionsIcon.moveIcon(0);
				// Position coverlay content to center of window
				$content.addClass("center");
			}

		} else {

			coverlayFadeComplete = false;
			console.log("coverlayFadeComplete", coverlayFadeComplete);

			// If not playing and wasPlaying is true, resume playing
			if (!states.playing && wasPlaying){
				playButton.togglePlay();
			}

			if (ajaxClosing) {
				// Animating opacity fixes flicker when using fadeIn in Safari
				$overlay.fadeTo(0, 0);
				$overlay.hide();
				$wrap.fadeTo(0, 0);
				$wrap.hide();
				// Ensure immediate scroll triggers offStationEvents()
				setTimeout(function(){
					states.coverlayOpen = false;
					console.log("states.coverlayOpen", states.coverlayOpen);
					coverlayFadeComplete = true;
					console.log("coverlayFadeComplete", coverlayFadeComplete);
					// Set current audio coverlay info
					audioContainer.setCurrentAudioCoverlayInfo();
					// Update remote UI, change info button color based on current audio coverlay
					var hasAjaxPresentation = coverlayText.detectAjaxPresentation();
					socketEmit.send("returnHasAjaxPresentation", hasAjaxPresentation);
				}, coverlayFadeDuration+100);
				// Special state to correct display of remote mainIcon during coverlay ajaxClosing
				// Override natural state of coverlayOpen variable to sync remote and desktop UI
				socketEmit.state("coverlayOpenFromAjaxClosing", false);
				//socketEmit.state("coverlayOpen", states.coverlayOpen);
				coverlayText.showAboutCoverlay(false);
				coverlayShareButtons.showAboutCoverlay(false);
			} else {
				// Override natural state of coverlayOpen variable to sync remote and desktop UI
				socketEmit.state("coverlayOpen", false);
				// Animating opacity fixes flicker when using fadeIn in Safari
				$overlay.fadeTo(coverlayFadeDuration, 0, function() {
					$overlay.hide();
				});
				$wrap.fadeTo(coverlayFadeDuration, 0, function() {
					$wrap.hide();
					// Ensure immediate scroll triggers offStationEvents()
					setTimeout(function(){
						states.coverlayOpen = false;
						console.log("states.coverlayOpen", states.coverlayOpen);
						coverlayFadeComplete = true;
						console.log("coverlayFadeComplete", coverlayFadeComplete);
						//socketEmit.state("coverlayOpen", states.coverlayOpen);
						coverlayText.showAboutCoverlay(false);
						coverlayShareButtons.showAboutCoverlay(false);
						// Set current audio coverlay info
						audioContainer.setCurrentAudioCoverlayInfo();
						// Update remote UI, change info button color based on current audio coverlay
						var hasAjaxPresentation = coverlayText.detectAjaxPresentation();
						socketEmit.send("returnHasAjaxPresentation", hasAjaxPresentation);
					}, 100);
				});
			}

			channel = radioKnobController.getChannel();

			// Fade in marquee and audio info
			marquee.fadeIn();
			audioInfo.fadeIn();

			if (ajaxClosing) {
				// Disable closedCaptionsIcon animation
				closedCaptionsIcon.disableAnimation();
				// Reset element position if it was moved due to vertical scroll bar width
				closedCaptionsIcon.moveIcon(0);
			} else {
				// Enable closedCaptionsIcon animation
				closedCaptionsIcon.enableAnimation();
				setTimeout(function(){
					// Reset element position if it was moved due to vertical scroll bar width
					closedCaptionsIcon.moveIcon(0);
				}, 900);
			}
		}
	}

	function detectScrollbarWidth() {
		// Detect global scrollbar width for specific browser
		//Source: https://davidwalsh.name/detect-scrollbar-width
		// Create the measurement node
		var scrollDiv = document.createElement("div");
		scrollDiv.className = "scrollbar-measure";
		document.body.appendChild(scrollDiv);

		// Get the scrollbar width
		scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
		console.log("scrollbarWidth", scrollbarWidth); // Mac: 15

		// Delete the DIV 
		document.body.removeChild(scrollDiv);
	}

	// Detect if element has vertical scroll bar
	//Source: https://stackoverflow.com/questions/4814398/how-can-i-check-if-a-scrollbar-is-visible
	$.fn.hasScrollbar = function() {
		return this.get(0).scrollHeight > this.height();
	}
	//Example usage: console.log('#elm has scroll bar: ' + $('#elm').hasScrollbar());

	function detectScrollbar(elm){
		return elm.hasScrollbar();
	}

	function windowResize(){
		// If vertical scrollbar is detected
		if($wrap.hasScrollbar()){
			// Adjust close button and close caption icon positions based on scroll bar width
			$closeButton.css({"margin-right": scrollbarWidth+"px"});
			// Disable closedCaptionsIcon animation
			closedCaptionsIcon.disableAnimation();
			closedCaptionsIcon.moveIcon(scrollbarWidth);
			// Position coverlay content to top of window
			$content.removeClass("center");
		}
		else{
			$closeButton.css({"margin-right": "0px"});
			closedCaptionsIcon.moveIcon(0);
			// Position coverlay content to top of window
			$content.addClass("center");
		}
	}

	function toggleCoverlay() {
		if (coverlayFadeComplete){
			_render();
		}
	};

	function toggleCoverlayFromAjax() {
		ajaxClosing = true;
		console.log("ajaxClosing", ajaxClosing);
		_render();
		ajaxClosing = false;
		console.log("ajaxClosing", ajaxClosing);
	};

	function scrollWrapCoverlay(data){
		//console.log("data", data);
		wrapDomEl.scrollTop += data*5;
	}

	function getScrollbarWidth() {
		return scrollbarWidth;
	}

	return {
		init: init,
		toggleCoverlay: toggleCoverlay,
		toggleCoverlayFromAjax: toggleCoverlayFromAjax,
		detectScrollbar: detectScrollbar,
		scrollWrapCoverlay: scrollWrapCoverlay,
		getScrollbarWidth: getScrollbarWidth
	};

})(); //coverlay

export {coverlay};