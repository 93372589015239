import {states} from './states.js';
import {radioKnobController} from './radioKnobController.js';
import {mouseScroll} from './mouseScroll.js';

// Phone rotate
var phoneRotate = (function(){
	
	//data
	var anguloAtual = 0;
	var pointerSpeedLimit;

	function angleDifference(data){
		if (!states.coverlayOpen && !states.qrCodeModalOpen && !states.ajaxPresentationOpen && !states.enterPageOpen && !states.autoTuning){

			// Dial rotation speed
			anguloAtual += data * 1;

			// Limit pointer speed
			pointerSpeedLimit = data * 0.4;

			// Sync anguloAtual local variables
			radioKnobController.setAnguloAtual(anguloAtual);
			mouseScroll.setAnguloAtual(anguloAtual);

			// Call switchChannel()
			radioKnobController.switchChannel(pointerSpeedLimit);
		}
	}

	function setAnguloAtual(data) {
		anguloAtual = data;
	}

	return {
		angleDifference: angleDifference,
		setAnguloAtual: setAnguloAtual
	};

})(); //phoneRotate

export {phoneRotate};